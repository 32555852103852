<template>
  <v-card v-if="show && results" class="pa-5">
    <v-card-title>
      <v-row>
        <v-col>
          <h3>Accessibility Test</h3>
          {{results.url}}
        </v-col>
        <v-col align="end" v-if="results.url && results.timestamp">
          {{$date.fullDateTime(results.timestamp)}}<br><br>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-5">
      <v-container fluid>
        <v-row>
          <v-col v-if="results.testEngine && results.testEngine.name">
            <b>Test Engine:</b><br>
            {{results.testEngine.name}} [{{results.testEngine.version}}]<br>
            Tools: <v-chip x-small v-for="(chip, index) in results.toolOptions.runOnly.values" :key="index">{{chip}}</v-chip>
          </v-col>
          <v-col v-if="results.testEnvironment">
            <b>Test Environment:</b><br>
            <span>{{results.testEnvironment.userAgent}}</span>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="my-5"></v-divider>
      <v-expansion-panels light multiple>
        <v-expansion-panel v-for="(group, index) in resultGroups" :key="`group-`+index">
          <v-expansion-panel-header :expand-icon="group.icon" :color="group.iconColor">
            <b>{{group.label}} ({{results[group.value].length}})</b> 
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list three-line dense>
              <v-list-item-group>
                <v-list-item :href="result.helpUrl" target=_blank v-for="(result, index) in results[group.value]" :key="`inapplicable-`+index">
                  <v-list-item-content>
                    <v-list-item-title>{{result.help}}</v-list-item-title>
                    <v-list-item-subtitle>{{result.description}}</v-list-item-subtitle>
                    <v-list-item-action-text>{{result.helpUrl}}</v-list-item-action-text>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'Accessibility.vue',
    data() {
      return {
        resultGroups: [
          {
            icon: 'mdi-alert-octagon',
            iconColor: 'red lighten-5',
            label:'Incomplete', 
            value: 'incomplete'
          },
          {
            icon: 'mdi-alert-octagon',
            iconColor: 'red lighten-5',
            label:'Violations', 
            value: 'violations'
          },
          {
            icon: 'mdi-alert-box',
            iconColor: 'blue-grey lighten-5',
            label: 'Inapplicable', 
            value: 'inapplicable'
          },
          {
            icon: 'mdi-check-circle',
            iconColor: 'green lighten-4',
            label:'Passes', 
            value: 'passes'
          },
        ],
      }
    },
    props:{
      show: {
        type: Boolean,
        default: false,
      },
      results: {
        type: Object,
        default() {
          return {}
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
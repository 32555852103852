<template>
  <v-dialog v-model="value" width="80%" persistent>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">Email This Report</span>
          <v-btn small dark fab absolute right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1">
          <v-row dense>
            <v-col cols="8">
              <v-row dense>
                <v-col>
                  <div class="text-h6 py-5">
                    Report Prepared for: <b>{{ form.fields.domain }}</b>
                  </div>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="form.fields.username"
                    type="text"
                    outlined
                    required
                    placeholder=""
                    @keydown.enter.prevent
                    label="From (Name)"
                    :rules="[$utilities.rules.required]"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-combobox
                    v-model="form.fields.emails" 
                    :hide-no-data="!searchEmail"
                    :search-input.sync="searchEmail" 
                    :items="emails" 
                    label="To Email(s) - Input email and press enter to add"
                    multiple outlined small-chips hide-selected solo required>
                    <template v-slot:no-data>
                      <v-list-item>
                        <span>Add Email: </span>
                        <v-chip label small>
                          {{ searchEmail }}
                        </v-chip>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-bind="attrs" :input-value="selected" label small>
                        <span class="pr-2">
                          {{ item }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-fieldset-basic title="Attached Files (click to open)" class="filelist">
                    <v-chip v-for="item in attachmentsList" :key="item.label" class="mr-1 text-capitalize" close @click:close="onFileRemove(item)">{{
                      item.label
                    }}</v-chip>
                  </v-fieldset-basic>
                </v-col>
              </v-row>
              <div class="text-h6 py-5">
                <v-divider></v-divider>
              </div>
              <v-row dense>
                <v-col class="pa-4">
                  <b>Recommendations</b>
                  <tiptap-vuetify v-model="form.fields.recommendations" :extensions="extensions"></tiptap-vuetify>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row dense>
                <v-col class="pa-4">
                  <v-card>
                    <v-card-title>
                      <span class="text-h6">What is this?</span>
                    </v-card-title>
                    <v-card-text>
                      <p>The email will be sent to the email address(es) provided above.</p>
                      <p>The recipients will receive an email that provides links to download all attachments associated with a scan.</p>
                      <p>
                        Add any notes regarding recommendations you may have for the recipients. This should be next steps on securing and increase
                        the sites performance.
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn dark color="blue-grey darken-4" @click="send()">Send</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
} from "tiptap-vuetify";

export default {
  name: "ScanEmail.vue",
  data() {
    return {
      form: {
        valid: false,
        fields: {
          emails: [],
          username: "",
          recommendations: "",
          reports: {},
          attachments: [],
          subject: "HOUNDDOG MONTHLY REPORT"
        },
      },
      extensions: [
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      sending: false,
      emails: [],
      searchEmail: null,
    };
  },
  emits: ["update:modelValue"],
  components: {
    TiptapVuetify,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    user() {
      return this.$store.getters["users/user"];
    },
    attachmentsList() {
      return this.form.fields.attachments.filter((item) => item.removed === false);
    },
  },
  mounted() {
    this.init();
  },
  created() {
    this.init();
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    init() {
      this.sending = false;
      this.form.fields.username = this.user.name;
      this.form.fields.attachments = this.item.attachments ?? [];
      this.form.fields.reports = {
        name: this.item.site_name,
        domain: this.item.url,
        url: this.item.report_pdf,
      };
      this.form.fields.emails = this.item.recipients ?? [];
    },
    onFileRemove(file) {
      const index = this.form.fields.attachments.findIndex((item) => item === file);
      if (index !== -1) {
        this.form.fields.attachments[index].removed = true;
      }
    },
    async send() {
      const isValid = this.$refs.form.validate();

      if (!isValid) {
        this.systemMessage("Please provide required fields!");
        return;
      }

      this.sending = true;
      this.$emit("send", this.form.fields);
    },
  },
  watch: {
    value(to) {
      if (to) {
        this.init();
      }
    },
    "item.attachments"(to) {
      if (to) {
        this.form.fields.attachments = to.map((item) => ({ label: item.file_label, file: item.file_url, removed: false }));
      }
    },
    'form.fields.emails': {
        handler(val, prev) {
          if (val.length === prev.length) return;
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const validEmails = val.filter(v => {
            return typeof v === 'string' && emailRegex.test(v);
          });
          this.form.fields.emails = validEmails;
        },
        deep: true,
      },
  },
};
</script>

<style scoped>
.filelist >>> fieldset {
  min-height: 70px;
}
</style>

import Moment from 'moment-timezone';
Moment.tz.setDefault("America/Chicago");

let offset = 0;

export default {
  vuetifyDate(){
    let ret = Moment().utcOffset(offset).format("YYYY-MM-DDTHH:mm");
    return (ret == 'Invalid date') ? '' : ret;
  },
  tmzFormatted(date){
    let ret = Moment(date).utcOffset(offset).format("MM/DD/YYYY h:mm A");
    return (ret == 'Invalid date') ? '' : ret;
  },
  fullDateTime(date, add = 0){
    let ret = Moment(date).add(add, 'days').utcOffset(offset).format("ddd MMM Do YYYY, h:mm A");
    return (ret == 'Invalid date') ? '' : ret;
  },
  fullDateTime2(date, add = 0){
    let ret = Moment(date).add(add, 'days').utcOffset(offset).format("MM/DD/YYYY, h:mm A");
    return (ret == 'Invalid date') ? '' : ret;
  },
  fullDate(date){
    let ret = Moment(date).utcOffset(offset).format("MMMM DD, YYYY");
    return (ret == 'Invalid date') ? '' : ret;
  },
  standardDate(date){
    let ret = Moment(date).utcOffset(offset).format("MM/DD/YYYY");
    return (ret == 'Invalid date') ? '' : ret;
  },
  standardDateMin(date){
    let ret = Moment(date).utcOffset(offset).format("M/D/YYYY");
    return (ret == 'Invalid date') ? '' : ret;
  },
  standardTime(date){
    let ret = Moment(date).utcOffset(offset).format("h:mm A");
    return (ret == 'Invalid date') ? '' : ret;
  },
  months(){
    return [
      {id: 'all', label: 'All'},
      {id: '01', label: 'January'},
      {id: '02', label: 'February'},
      {id: '03', label: 'March'},
      {id: '04', label: 'April'},
      {id: '05', label: 'May'},
      {id: '06', label: 'June'},
      {id: '07', label: 'July'},
      {id: '08', label: 'August'},
      {id: '09', label: 'September'},
      {id: '10', label: 'October'},
      {id: '11', label: 'November'},
      {id: '12', label: 'December'}
    ];
  },
  monthNumberByDate(date){
    return Moment(date).utcOffset(offset).format("MM");
  },
}
<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="160px" height="160px" viewBox="0 0 160 160" enable-background="new 0 0 160 160" xml:space="preserve">
    <path fill="#1C84C7" d="M79.999,10.081c-38.61,0-69.919,31.307-69.919,69.917c0,38.623,31.309,69.922,69.919,69.922
      c38.621,0,69.922-31.299,69.922-69.922C149.92,41.387,118.62,10.081,79.999,10.081z M80.002,143.478
      c-35.056,0-63.479-28.424-63.479-63.48c0-35.049,28.423-63.475,63.479-63.475c35.057,0,63.475,28.426,63.475,63.475
      C143.477,115.054,115.059,143.478,80.002,143.478z"/>
    <path fill="#1C84C7" d="M126.277,44.815L98.793,74.292L85.137,50.549l-21.22,25.677L52.931,59.597L25.65,99.283
      c8.134,22.068,29.352,37.812,54.245,37.812c31.922,0,57.793-25.881,57.793-57.805C137.688,66.373,133.446,54.441,126.277,44.815z"/>
  </svg>
</template>

<script>
  export default {
    name: 'ManageWP.vue',
    props: {
      icon: {
        type: String,
        default: '$cancel'
      }
    }
  }
</script>
<template>
  <v-dialog v-model="value" width="550px" persistent>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Scan Details</span>
          <v-btn small dark fab absolute right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container class="pa-5">
            <v-row>
              <v-col>
                <v-select
                  v-model="form.fields.report_theme"
                  :items="$strings.themeOptions()"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  required
                  label="Theme"
                  placeholder="Theme"
                  @keydown.enter.prevent
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.fields.report_label"
                  type="text"
                  outlined
                  dense
                  label="Report Label"
                  placeholder="Report Label"
                  @keydown.enter.prevent
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn dark color="blue-grey darken-4" @click="save">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'ScanDetails.vue',
  data() {
    return {
      selectedIndex: 0,
      imageSelected: null,
      form:{
        valid: false,
        fields:{
          theme: 'default',
          report_label: 'Monthly Maintenance',
        }
      }
    }
  },
  emits: ['update:modelValue'],
  props:{
    value: {
      type: Boolean,
      default: false,
    },
    id:{
      type: Number
    },
  },
  created(){
    this.form.fields.id = this.id;
    this.get();
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async get(){
      let self = this;
      await this.$store.dispatch("scans/get", { 
        id: this.id
      }).then((response) => {
        self.form.fields = {...response}
      });
    },
    async save(){
      if(this.$refs.form.validate()){
        let fields = { ...this.form.fields};
        await this.$store.dispatch("scans/update", fields);
        this.updateValue(false);
      }else{
        this.systemMessage('Please validate the form!');
      }
    },
  },
  watch:{
    id(to){
      this.form.fields.id = to;
      this.get();
    },
  }
}
</script>

<style>

</style>
<template>
  <v-card elevation="0" outlined>
    <v-card-title>Report Branding</v-card-title>
    <v-card-text v-if="userMeta && userMeta.branding != null && userMeta.branding != ''">
      <v-row dense>
        <v-col class="text-center">
          <div class="company-logo-wrapper">
            <v-company-logo :is-inline="true" width="150px"></v-company-logo><br>
          </div>
          <v-btn
            x-small
            dark
            color="blue-grey darken-4"
            @click="clearBranding"
            class="mt-2"
          >
            <v-icon x-small>mdi-close</v-icon>
            Delete
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="text-center" v-else>
      <v-alert dense type="info" outlined class="mb-0" dark >You currently do not have a logo set for your company.<br>Once uploaded, this will be added to the top left of all reports.</v-alert>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row align="center">
        <v-col cols="12" md="6" class="text-start">
          <h4 class="mb-5 ml-4"><b>Requirements:</b></h4>
          <ul>
            <li class="text-caption">Must be a PNG, JPG or BMP file</li>
            <li class="text-caption">Must be less than 1MB</li>
          </ul>
        </v-col>
        <v-col cols="12" md="6" class="text-start py-5">
          <h4 class="mb-5 ml-4"><b>Upload New:</b></h4>
          <v-form
            ref="form"
            v-model="form.valid"
            lazy-validation>
            <v-file-input
              v-model="imageSelected"
              ref="file"
              outlined
              show-size
              accept="image/png, image/jpeg, image/bmp"
              label="Select a file"
              prepend-icon="mdi-image"
              hide-details
              @change="fileUpload($event)"
              class="ma-3"
              :rules="[$utilities.rules.max2mb, $utilities.rules.onlyImages]"
            ></v-file-input>
          </v-form>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col>
          <table>
            <tr>
              <td width="80">
                <v-switch
                  :input-value="form.fields.hide_logo"
                  inset
                  light
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  @change="toggleLogo()"
                  color="green2"
                ></v-switch>
              </td>
              <td colspan="2">
                <h3>Hide Hounddog Logo</h3>
                This will remove the Hounddog logo from the top of all reports.
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ReportBranding.vue',
  data() {
    return {
      imageSelected: null,
      form:{
        valid: false,
        fields: {
          hide_logo: 0,
        },
      },
    }
  },
  computed:{
    user(){
      return this.$store.getters['users/user'];
    },
    userMeta(){
      return this.$store.getters['userMeta/get'];
    },
    meta(){
      return this.$store.getters['userMeta/get'];
    }
  },
  async created(){
    this.form.fields.hide_logo = this.meta.hide_logo;
  },
  methods:{
    async toggleLogo(){
      await this.$store.dispatch("userMeta/toggleLogo");
    },
    clearBranding(){
      this.$store.dispatch("userMeta/updateBranding", {
        branding: null
      })
      .then(() => {
        self.imageSelected = null;
      });
    },
    async fileUpload(e){
      if(e == null) return;
      if(this.$refs.form.validate()){
        let base64File = await this.$utilities.convertToBase64(e);
        let self = this;
        this.$store.dispatch("userMeta/updateBranding", {
          branding: base64File
        })
        .then(() => {
          self.imageSelected = null;
        });
      }
    },
  },
  watch:{
    meta (to){
      this.form.fields.hide_logo = to.hide_logo;
    }
  }
}
</script>

<style>

</style>
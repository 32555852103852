<template>
  <v-dialog v-model="value" max-width="800px" persistent>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card min-height="350px">
        <v-card-title>
          <span class="text-h5">Update Site</span>
          <v-spacer></v-spacer>
          <v-btn small dark fab right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-5">

          <v-tabs v-model="tab" color="blue-grey lighten-2" background-color="blue-grey lighten-5" class="pb-5">
            <v-tab>Site Details</v-tab>
            <v-tab>Report Branding</v-tab>
            <v-tab>Customize Modules</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container>
                <v-row align="center">
                  <v-col>
                    <v-text-field
                      v-model="form.fields.name"
                      type="text"
                      outlined
                      dense
                      required
                      placeholder="Client Name"
                      label="Client Name"
                      @keydown.enter.prevent
                      :rules="[$utilities.rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="form.fields.host"
                      outlined
                      dense
                      required
                      label="Hosting ISP Name"
                      placeholder="Hosting ISP Name"
                      @keydown.enter.prevent
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col>
                    <v-select
                      v-model="form.fields.framework"
                      :items="$strings.frameworkOptions()"
                      item-text="label"
                      item-value="value"
                      outlined
                      dense
                      required
                      label="Framework"
                      placeholder="Framework"
                      @keydown.enter.prevent
                      :rules="[$utilities.rules.required]"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="form.fields.started_on"
                      type="date"
                      outlined
                      dense
                      required
                      label="Started On"
                      placeholder="Started"
                      @keydown.enter.prevent
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="form.fields.frequency"
                      :items="$strings.frequencyOptions()"
                      item-text="label"
                      item-value="label"
                      outlined
                      dense
                      required
                      label="Frequency"
                      placeholder="Frequency"
                      @keydown.enter.prevent
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
              <v-col>
                <div class="text-subtitle-1">Enter email addresses for report recipients.</div>
                <v-combobox label="Emails"
                  v-model="form.fields.recipients" 
                  :hide-no-data="!searchEmail"
                  :search-input.sync="searchEmail" 
                  :items="emails" 
                  dense multiple outlined small-chips hide-selected solo>
                  <template v-slot:no-data>
                    <v-list-item>
                      <span>Add Email: </span>
                      <v-chip label small>
                        {{ searchEmail }}
                      </v-chip>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                      v-bind="attrs" :input-value="selected" label small>
                      <span class="pr-2">
                        {{ item }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col align="center">
                    <v-btn dark color="blue-grey darken-4" @click="save" class="my-5">Save</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container >
                <v-row>
                  <v-col align="center">
                    <v-alert type="info" outlined>Modifying the logo below will only update the logo for this site's scan reports.</v-alert>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col align="center">
                    <h5 class="mb-2"><b>Account Logo:</b></h5>
                  </v-col>
                  <v-col align="center">
                    <h5 class="mb-2"><b>Current Override:</b></h5>
                  </v-col>
                  <v-col align="center">
                    <h5 class="mb-2"><b>Upload New Logo:</b></h5>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col align="center">
                    <v-company-logo width="165px"></v-company-logo>
                  </v-col>
                  <v-col align="center">
                    <div v-if="!fileLoading">
                      <v-sheet v-if="form.fields.override_branding" color="#efefef" class="pa-4" rounded>
                        <v-company-logo width="165px" :override="form.fields.override_branding"></v-company-logo>                  
                      </v-sheet>
                      <v-alert
                        v-else
                        outlined
                        dense
                        color="#B0BEC5"
                        class="text-caption mb-0"
                      >
                      None
                      </v-alert>
                    </div>
                    <v-progress-circular
                      :size="30"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                  <v-col align="center">
                    <v-form
                      ref="form"
                      v-model="form.valid"
                      lazy-validation
                      v-if="!fileLoading"
                    >
                      <v-file-input
                        v-model="imageSelected"
                        ref="file"
                        outlined
                        show-size
                        accept="image/png, image/jpeg, image/bmp"
                        label="Select a override logo"
                        prepend-icon="mdi-image"
                        hide-details
                        @change="fileUpload($event)"
                        class="ma-0"
                        solo
                        dense
                        style="font-size: 12px;"
                        :rules="[$utilities.rules.max2mb, $utilities.rules.onlyImages]"
                      ></v-file-input>
                    </v-form>
                    <v-progress-circular
                      :size="30"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col align="center">

                  </v-col>
                  <v-col align="center">
                    <v-btn
                      x-small
                      dark
                      color="blue-grey darken-4"
                      @click="clearBranding"
                      rounded
                      v-if="form.fields.override_branding"
                    >
                      <v-icon x-small>mdi-close</v-icon>
                      delete
                    </v-btn>
                  </v-col>
                  <v-col align="center">

                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>  
            <v-tab-item>
              <div class="text-subtitle-1 pt-0 pb-2 text-center">The modules below will be included in this site's scan reports.<br>You can re-order them or add and remove as needed.</div>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-report-selector v-model="reports" :id="ref_id" />
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
                <v-row dense>
                  <v-col align="center">
                    <v-btn dark color="blue-grey darken-4" @click="saveReports" class="my-5">Save</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SitesUpdate.vue',
    data() {
      return {
        tab: 0,
        defaultItems: {},
        imageSelected: null,
        fileLoading: false,
        form: {
          valid: false,
          fields: {
            active: 0,
            uptime: 0,
            name: '',
            started_on: '',
            frequency: '',
            framework: '',
            host: '',
            notes: '',
            override_branding: '',
            recipients: []
          }
        },
        reports: [],
        ref_id: 0,
        emails: [],
        searchEmail: ""
      }
    },
    emits: ['update:modelValue'],
    props:{
      value:{
        type: Boolean,
        default: false
      },
      id:{
        type: [Number, String]
      },
    },
    created(){
      this.form.fields.id = this.id;
    },
    methods:{
      async toggleClient(id){
        await this.$store.dispatch("sites/toggle", {id: id})
          .then(() => {
            this.form.fields.active = (this.form.fields.active == 1) ? 0 : 1;
          });
      },
      async toggleUptimeMonitoring(id){
        await this.$store.dispatch("sites/toggleUptime", {id: id})
          .then(() => {
            this.form.fields.uptime = (this.form.fields.uptime == 1) ? 0 : 1;
          });
      },
      updateValue (value) {
        this.$emit('input', value);
      },
      async get(){
        await this.$store.dispatch("sites/get", {id: this.id})
          .then((response) => {
            const site = response;
            this.form.fields = {...response};
            this.reports = site.report_list ?? [];
            this.ref_id = site.id;
          });
      },
      clearBranding(){
        let self = this;
        this.$store.dispatch("sites/updateBranding", {
          override_branding: '',
          id: this.id
        })
        .then(() => {
          self.imageSelected = null;
          self.form.fields.override_branding = '';
        });
      },
      async fileUpload(e){
        if(e == null) return;
        this.fileLoading = true;
        if(this.$refs.form.validate()){
          let base64File = await this.$utilities.convertToBase64(e);
          let self = this;
          this.$store.dispatch("sites/updateBranding", {
            override_branding: base64File,
            id: this.id
          })
          .then((response) => {
            self.imageSelected = null;
            self.form.fields.override_branding = response[0];
            self.fileLoading = false;
          });
        }
      },
      save() {
        let self = this;
        if(this.$refs.form.validate()) {
          this.$store.dispatch("sites/update", this.form.fields)
          .then(() => {
            self.updateValue(false);
          });
        }else{
          this.systemMessage('Please validate the form!');
        }
      },
      saveReports() {
        let self = this;
        this.$store.dispatch("sites/updateReport", {
            reports_list: this.reports,
            id: this.id
          })
          .then((response) => {
            self.form.fields.report_list = response[0];
            self.reports = response[0];
            self.updateValue(false);
          });
      }
    },
    watch: {
      id(to){
        this.form.fields.id = to;
        this.get();
      },
      value(to){
        this.tab = to === false ? 0 : this.tab;
      },
      'form.fields.recipients': {
        handler(val, prev) {
          if (val.length === prev.length) return;
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const validEmails = val.filter(v => {
            return typeof v === 'string' && emailRegex.test(v);
          });
          this.form.fields.recipients = validEmails;
        },
        deep: true,
      },
    }
  }
</script>

<style lang="scss">
</style>
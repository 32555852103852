<template>
  <div>
    <v-modal-posts-insert v-model="dialogInsert"></v-modal-posts-insert>
    <v-modal-posts-delete v-model="dialogDelete" :id="selectedIndex"></v-modal-posts-delete>
    <v-modal-posts-update v-model="dialogUpdate" :id="selectedIndex"></v-modal-posts-update>

    <v-page-title title="Knowledge Base">
      <v-btn elevation="0" 
        color="white" 
        small 
        to="/knowledge-base" class="mr-2">&lt; KB Home</v-btn>
      <v-btn 
        elevation="0" 
        color="primary" 
        small 
        dark 
        @click="dialogInsert = true"
      >
        <v-icon left small>mdi-plus</v-icon>
        New Post
      </v-btn>
    </v-page-title>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" v-if="!post">
      <v-loader></v-loader> 
    </v-card>
    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" v-else>
      <v-card-title>
        <v-btn
          color="primary"
          elevation="0"
          small
          dark
          fab
          absolute
          right
          @click="dialogUpdate = true"
          v-if="isEditable"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <section>
          <h3 class="mb-0">{{ post.title }}</h3>
          <h5 class="mb-0 grey--text" style="line-height: 1.5em;">
            Posted By: {{ post.name }}<br>
            Posted On: {{ $date.fullDateTime2(post.created_at) }}
          </h5>
        </section>
      </v-card-title>
      <v-card-text class="black--text">
        <div v-html="post.message" style="white-space: pre-wrap;max-height: 500px;overflow-y: auto;"></div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'KBPost.vue',
    data() {
      return {
        selectedIndex: 0,
        dialogInsert: false,
        dialogDelete: false,
        dialogUpdate: false,
        post: {},
      }
    },
    async created(){
      this.selectedIndex = this.id;
      if(!this.$utilities.isEmpty(this.id)){
        await this.getPost();
      }else{
        this.$router.push({name: 'Knowledge Base'});
      }
    },
    computed:{
      user(){
        return this.$store.getters['users/user'];
      },
      id(){
        return this.$route.params.id
      },
      posts(){
        return this.$store.getters['posts/get'];
      },
      isEditable(){
        const isAuthor = (this.post.user_id === this.user.id);
        const isWithinHour = (new Date() - new Date(this.post.created_at)) <= 3600000;
        return (isAuthor && isWithinHour) || this.user.super;
      }
    },
    methods: {
      async getPost(){
        this.post = await this.$store.dispatch('posts/get', {id: this.id});
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
<template>
  <div>
    <v-card max-width="1200" class="mx-auto" elevation="0" color="transparent">
      <v-container>
        <v-row dense class="my-4">
          <v-col class="text-center" v-if="credits">
            <h1>Thank you for your purchase!</h1>
            <p>Credits have immediately been added to your account.</p>
            <br><br>
            <b>Your current credit count:</b>
            <v-sheet class="rounded-pill mx-auto teal pa-2" elevation="5" width="200px" v-if="credits">
              <h1 class="text-h3 white--text mb-0">{{credits.remaining_credits}}</h1>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  import CanvasConfetti from 'canvas-confetti';

  export default {
    name: 'Success.vue',
    data() {
      return {
      }
    },
    computed: {
      credits(){
        return this.$store.getters['transactions/credits'];
      },
    },
    async created(){
      CanvasConfetti({
        particleCount: 200,
        startVelocity: 40,
        spread: 360,
        origin: {
          y: 0.2
        }
      });
      let self = this;
      await this.$store.dispatch('transactions/activate', {
        token: self.$route.params.token
      })
      .then(() => {
        setTimeout(function(){
          self.$router.push({name: 'Billing'});
        },5000)
      });
    }
  }
</script>

<style lang="scss">
</style>
<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100.60001 115.89999"><path id="Fill-3" d="m59.6,63.3l20.4,11.8-29.7,17.2-20.4-11.8-20.4,11.8,40.9,23.59999,50.2-28.89999v-47.10001l-41.00001,23.40001ZM0,76.10001V29L50.2,0l40.90001,23.7-20.39999,11.7-20.4-11.7-29.8,17.10001,20.4,11.8L0,76.10001Z" fill="#000000"/></svg>
</template>

<script>
  export default {
    name: 'Sucuri.vue',
    props: {
      icon: {
        type: String,
        default: '$cancel'
      }
    }
  }
</script>
<template>
  <v-dialog v-model="value" max-width="800px" persistent rounded>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Templates</span>
          <v-btn small dark fab absolute right text @click="onClickClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.fields.code"
                type="text"
                outlined
                dense
                readonly
                placeholder="Client Name"
                label="Client Name" />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.fields.name"
                outlined
                dense
                readonly
                label="Hosting ISP Name"
                placeholder="Hosting ISP Name" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-editor
                v-model="rawhtml"
                tinymce-script-src="./tinymce.min.js"
                model-events="change keydown blur focus paste"
                output-format="html"
                :api-key="editorKey"
                :disabled="false"
                :init="{
                  forced_root_block: 'div',
                  entity_encoding: 'raw',
                  plugins: 'lists link image table code help wordcount',
                  height: '60vh',
                }">
              </v-editor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="mx-auto py-5">
            <v-btn dark color="blue-grey lighten-1" @click="onClickSave"
              >Save</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "EmailTemplatesUpdate.vue",
  data() {
    return {
      ref_id: 0,
      rawhtml: "",
      defaultHtml: "",
      form: {
        valid: false,
        fields: {
          id: 0,
          code: "",
          name: "",
          description: "",
          params: "",
          html: "",
        },
      },
    };
  },
  emits: ["update:modelValue"],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
    },
  },
  computed: {
    editorKey() {
      return process.env.VUE_APP_API_ENDPOINT;
    },
  },
  mounted() {
    if (this.ref_id) {
      this.get();
    }
  },
  methods: {
    async get() {
      await this.$store
        .dispatch("emailTemplates/info", { id: this.ref_id })
        .then((data) => {
          this.form.fields = { ...data };
          this.rawhtml = data.html;
        });
    },
    onUpdateValue(value) {
      this.$emit("input", value);
    },
    onClickClose() {
      this.onUpdateValue(false);
    },
    onClickSave() {
      let self = this;
      this.$store
        .dispatch("emailTemplates/update", {
          ...self.form.fields,
          id: self.id,
          html: self.rawhtml,
        })
        .then((data) => {
          self.rawhtml = data.html;
          self.onUpdateValue(false);
        });
    },
  },
  watch: {
    id(to) {
      this.ref_id = to;
      this.get();
    },
  },
};
</script>

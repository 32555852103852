<template>
  <div>
    <v-container class="mt-5">
      <v-row dense no-gutters>
        <v-col class="text-center">
          <a href="/" plain text inline>
            <v-logo :show-big="true" width="200px"></v-logo>
          </a>
          <h2 class="text-h4 text-center">Logged out successfully!</h2>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'Logout.vue',
    data() {
      return {
      }
    },
    created(){
      let self = this;
      this.userLogout()
        .then(() => {

          this.$store.commit('sites/SET_RESET');
          this.$store.commit('scans/SET_RESET');
          this.$store.commit('messages/SET_RESET');
          this.$store.commit('transactions/SET_RESET');
          this.$store.commit('uptime/SET_RESET');
          this.$store.commit('userMeta/SET_RESET');
          this.$store.commit('users/SET_RESET');
          this.$store.commit('emailTemplates/SET_RESET');

          self.systemMessage('Logged out successfully!');
          setTimeout(() => {
            self.$router.push({ name: 'Login' });
          },1000)
        })
        .catch(() => {
          self.systemMessage('Error occurred. Try again!')
        })

    },
    methods:{
      ...mapActions({
          'userLogout':'users/logout',
      }),
    }
  }
</script>

<style lang="scss">
</style>
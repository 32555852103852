<template>
   <v-container fluid pa-0>
      <v-row dense>
         <v-col>
            <v-card class="mx-auto">
               <v-card-title>
                  <span class="text-subtitle-1">MODULES</span>
               </v-card-title>
               <v-card-text style="max-height: 250px; overflow-y: auto;">
                  <v-checkbox v-for="(item) in available" class="py-2" :key="item.no" hide-details :input-value="item.selected" @change="onSelectionAdd($event, item)" :label="item.name" />
               </v-card-text>
            </v-card>
         </v-col>
         <v-col cols="1" class="d-flex flex-column justify-center align-center">
            <v-tooltip top>
               <template v-slot:activator="{ on, attrs }">
                  <v-btn elevation="0" color="blue-grey darken-4" icon small @click="onAssignClick" mb-2 class="square-btn" v-bind="attrs" v-on="on">
                     <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>
               </template>
               <span>Add to future reports</span>
            </v-tooltip>
            <v-tooltip top>
               <template v-slot:activator="{ on, attrs }">
                  <v-btn elevation="0" color="blue-grey darken-4" icon small @click="onRemoveClick" mb-2 class="square-btn" v-bind="attrs" v-on="on">
                     <v-icon small>mdi-chevron-left</v-icon>
                  </v-btn>
               </template>
               <span>Remove from future reports</span>
            </v-tooltip>
         </v-col>
         <v-col>
            <v-card class="mx-auto">
               <v-card-title>
                  <span class="text-subtitle-1">CURRENT</span>
                  <v-flex d-flex flex-row justify-end align-center>
                     <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn elevation="0" icon small color="white" v-bind="attrs" v-on="on" @click="moveItem('up')">
                              <v-icon small>mdi-chevron-up</v-icon>
                           </v-btn>
                        </template>
                        <span>Move Up</span>
                     </v-tooltip>
                     <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn elevation="0" icon small color="white" v-bind="attrs" v-on="on" @click="moveItem('down')">
                              <v-icon small>mdi-chevron-down</v-icon>
                           </v-btn>
                        </template>
                        <span>Move Down</span>
                     </v-tooltip>
                  </v-flex>
               </v-card-title>
               <v-card-text style="max-height: 250px; overflow-y: auto;">
                  <v-checkbox v-for="(item) in assigned" class="py-2" :key="item.no" hide-details :input-value="item.selected" @change="onSelectionRemove($event, item)" :label="item.name" />
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   export default {
      name: 'ReportSelector.vue',
      data() {
         return {
            refId: 0,
            available: [],
            assigned: [],
            selectedIndex: -1,
         }
      },
      props:{
         value:{
            type: Array
         },
         id: {
            type: [Number, String]
         }
      },
      mounted() {
         this.initList();
      },
      methods: {
         updateValue (value) {
            this.$emit('input', value);
         },
         initList() {
            const rpt = this.$strings.scanList().map(item => {
               item.selected = false;
               return item;
            });
            if (this.value) {
               this.available = rpt.filter(r => !this.value.includes(r.name)).sort((a, b) => a.name.localeCompare(b.name));
               this.assigned = this.value
                  .map(val => rpt.find(r => r.name === val))
                  .filter(item => item); 
               this.selectedIndex = 0;
            } else {
               this.available = rpt.sort((a, b) => a.name.localeCompare(b.name));
            }
         },
         onSelectionAdd(selected, item) {
            const index = this.available.indexOf(item);
            this.available[index].selected = selected;
         },
         onSelectionRemove(selected, item) {
            const index = this.assigned.indexOf(item);
            this.assigned[index].selected = selected;
         },
         onAssignClick() {
            const selected = this.available.filter(r => r.selected === true);
            if (selected.length > 0) {
               const items = selected.map((item) => {
                  item.selected = false;
                  return item;
               })
               this.assigned.push(...items);
               this.available = this.available.filter(r => !this.assigned.some(a => a.no === r.no));
               this.available.sort((a, b) => a.name.localeCompare(b.name));
               this.updateValue(this.assigned.map(r => r.name));
            }
         },
         onRemoveClick() {
            const selected = this.assigned.filter(r => r.selected === true);
            if (selected.length > 0) {
               const items = selected.map((item) => {
                  item.selected = false;
                  return item;
               })
               this.available.push(...items);
               this.assigned = this.assigned.filter(r => !this.available.some(a => a.no === r.no));
               this.available.sort((a, b) => a.name.localeCompare(b.name));
               this.updateValue(this.assigned.map(r => r.name));

               if (this.assigned.length === 0) {
                  this.selectedIndex = -1;
               }
            }
         },
         moveItem(direction) {
            if (this.selectedIndex < 0) return;
            const index = this.selectedIndex;
            if (direction === 'up' && index > 0) {
               this.swapItems(index, index - 1);
            } else if (direction === 'down' && index < this.assigned.length - 1) {
               this.swapItems(index, index + 1);
            }
         },
         swapItems(fromIndex, toIndex) {
            const temp = this.assigned[fromIndex];
            this.assigned.splice(fromIndex, 1);
            this.assigned.splice(toIndex, 0, temp);
            this.selectedIndex = toIndex;
            this.updateValue(this.assigned.map(r => r.name));
         },
      },
      watch: {
         id(to) {
            this.refId = to;
            this.initList();
         }
      }
   }
</script>

<style lang="scss">
.div-action {
   width: 120px;
   gap: 5px;
   .v-btn {
      width: 100%;
   }
}
.div-order {
   width: 10px;
   gap: 5px;
   .v-btn {
      min-width: 25px !important;
      padding: 0 !important;
      .v-btn__content {
         .v-icon--right {
            margin: 0 !important;
         }
         .v-icon--left {
            margin: 0 !important;
         }
      }
   }
}
.square-btn {
   width: 25px !important;
   padding: 0 !important;
}
</style>

let st = null;

const state = () => ({
  message: null,
  show: false,
})

const getters = {
  message: state => state.message,
  show: state => state.show,
}

const actions = {
  setMessage(context, payload) {
    context.commit("SET_MESSAGE", payload);
    st = setTimeout(() => {
      context.dispatch('clearMessage'); 
    },5000);  
  },
  setShow(context, payload) {
    clearTimeout(st)
    context.commit("SET_SHOW", payload);
  },
  clearMessage(context) {
    context.commit("SET_CLEAR");
  }
}

const mutations = {
  SET_SHOW(state, payload) {
    state.show = payload;
  },  
  SET_MESSAGE(state, payload) {
    state.message = payload;
    state.show = true;
  },  
  SET_CLEAR(state) {
    state.message = '';
    state.show = false;
  },  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
import Vue from "vue";
import apiService from "@/services/api.js";
import i18n from "@/lang";

const model = "/email-templates";
const endpoints = {
  all: model + "/all",
  info: model + "/info",
  update: model + "/update",
};
const mutation_calls = {
  all: "SET_ALL",
  info: "SET_INFO",
  update: "SET_UPDATE",
  reset: "SET_RESET",
};

const initialState = () => ({
  all: [],
  get: null,
});

const state = initialState();

const getters = {
  all: (state) => state.all,
  get: (state) => state.get,
};

const actions = {
  async all(context) {
    return new Promise((resolve, reject) => {
      apiService
        .post(endpoints.all)
        .then((response) => {
          let payload = response.data;
          context.commit(mutation_calls.all, payload);
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  async info(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post(endpoints.info, {
          id: fields.id,
        })
        .then((response) => {
          let payload = response.data;
          delete response.data.id;
          context.commit(mutation_calls.info, payload);
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  async update(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post(endpoints.update, { ...fields })
        .then((response) => {
          let payload = response;
          context.commit(mutation_calls.update, fields);
          context.dispatch(
            "system/setMessage",
            i18n.t("uptimerobot.update.status.success"),
            { root: true }
          );
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch(
            "system/setMessage",
            i18n.t("uptimerobot.update.status.error"),
            { root: true }
          );
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_ALL(state, payload) {
    state.all = payload;
  },
  SET_INFO(state, payload) {
    state.get = payload;
  },
  SET_UPDATE(state, payload) {
    const index = state.all.findIndex((row) => row.id == payload.id);
    Vue.set(state.all, index, payload);
  },
  SET_RESET(state) {
    Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Vue from "vue";

import Support from "./Support.vue";

Vue.component("v-modal-support", Support);

import UsersForgot from "./users/Forgot.vue";
import UsersInsert from "./users/Insert.vue";
import UsersUpdate from "./users/Update.vue";
import UsersDelete from "./users/Delete.vue";
import UsersResendInvite from "./users/ResendInvite.vue";
import UsersGift from "./users/Gift.vue";

Vue.component("v-modal-users-forgot", UsersForgot);
Vue.component("v-modal-users-insert", UsersInsert);
Vue.component("v-modal-users-update", UsersUpdate);
Vue.component("v-modal-users-delete", UsersDelete);
Vue.component("v-modal-users-resend-invite", UsersResendInvite);
Vue.component("v-modal-users-gift", UsersGift);

import Wordpress from "./integrations/Wordpress.vue";
import Slack from "./integrations/Slack.vue";
import GTMetrix from "./integrations/GTMetrix.vue";

Vue.component("v-modal-wordpress", Wordpress);
Vue.component("v-modal-slack", Slack);
Vue.component("v-modal-gtmetrix", GTMetrix);

import ScanDebug from "./scans/Debug.vue";
import ScanDetails from "./scans/Details.vue";
import ScanReorder from "./scans/Reorder.vue";
import ScanDelete from "./scans/Delete.vue";
import ScanDeleteAll from "./scans/DeleteAll.vue";
import ScanEmail from "./scans/Email.vue";
import ScanAttachment from "./scans/Attachment.vue";
import Scan from "./scans/Scan.vue";

Vue.component("v-modal-scan-debug", ScanDebug);
Vue.component("v-modal-scan-details", ScanDetails);
Vue.component("v-modal-scan-reorder", ScanReorder);
Vue.component("v-modal-scan-delete", ScanDelete);
Vue.component("v-modal-scan-delete-all", ScanDeleteAll);
Vue.component("v-modal-scan-email", ScanEmail);
Vue.component("v-modal-scan-attachment", ScanAttachment);
Vue.component("v-modal-scan", Scan);

import SitesInsert from "./sites/Insert.vue";
import SitesUpdate from "./sites/Update.vue";
import SitesDelete from "./sites/Delete.vue";

Vue.component("v-modal-sites-insert", SitesInsert);
Vue.component("v-modal-sites-update", SitesUpdate);
Vue.component("v-modal-sites-delete", SitesDelete);

import TaskProgress from "./progress/TaskProgress.vue";
Vue.component("v-modal-task-progress", TaskProgress);

import MessagesInsert from "./messages/Insert.vue";
Vue.component("v-modal-messages-insert", MessagesInsert);

import EmailTempalesUpdate from "./templates/Update.vue";
Vue.component("v-modal-tempales-update", EmailTempalesUpdate);

import PostsInsert  from './posts/Insert.vue';
import PostsUpdate  from './posts/Update.vue';
import PostsDelete  from './posts/Delete.vue';

Vue.component('v-modal-posts-insert',   PostsInsert);
Vue.component('v-modal-posts-update',   PostsUpdate);
Vue.component('v-modal-posts-delete',   PostsDelete);

<template>
  <v-snackbar 
    app 
    bottom
    left 
    color="info" 
    v-model="show" 
    :timeout="timeout">
    {{message}}
  </v-snackbar>
</template>

<script>
export default {
  name: 'SystemMessages.vue',
  data () {
    return {
      timeout: 5000,
    }
  },
  computed: {
    message(){
      return this.$store.getters['system/message'];
    },
    show(){
      return this.$store.getters['system/show'];
    }
  },
}
</script>

<style>
</style>
<template>
  <v-dialog v-model="value" max-width="650px" persistent>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card class="pb-5">
        <v-card-title>
          <span class="text-h5">Forgotten Password</span>
          <v-btn small dark fab absolute right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                Enter your email address and we will send your email a temporary password. 
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.fields.email"
                  outlined
                  required
                  placeholder="Email"
                  @keydown.enter.prevent
                  label="Email"
                  :rules="[$utilities.rules.required, $utilities.rules.isEmail]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="blue-grey darken-4" @click="save">Send</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'UserForgot.vue',
  data() {
    return {
      form:{
        valid: false,
        fields:{
          email: '',
        }
      },
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
  },
  computed:{
    users(){
      return this.$store.getters['users/all'];
    },
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    save(){
      if(this.$refs.form.validate()){
        this.$store.dispatch('users/passwordEmail', this.form.fields)
        this.updateValue(false);
      }
    }
  }
}
</script>

<style>

</style>
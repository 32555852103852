import Vue from 'vue'
import Vuetify from 'vuetify'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

import Google           from '@/components/icons/Google.vue'
import GTMetrix         from '@/components/icons/GTMetrix.vue'
import Lighthouse       from '@/components/icons/Lighthouse.vue'
import ManageWP         from '@/components/icons/ManageWP.vue'
import McAfee           from '@/components/icons/McAfee.vue'
import Norton           from '@/components/icons/Norton.vue'
import PageSpeed        from '@/components/icons/PageSpeed.vue'
import Sucuri           from '@/components/icons/Sucuri.vue'
import VirusTotal       from '@/components/icons/VirusTotal.vue'
import Wave             from '@/components/icons/Wave.vue'
import Wordfence        from '@/components/icons/Wordfence.vue'
import YSlow            from '@/components/icons/YSlow.vue'

import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

const vuetify = new Vuetify()

Vue.use(Vuetify);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

const opts = {
  lang: {
    current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: '#354449',
        secondary: '#FC5C29',
        tertiary: '#E7E8DE',
        black: '#354449',
        green2: '#4CAF50'
      },
      dark: {
        primary: '#354449', 
        secondary: '#FC5C29',
        tertiary: '#E7E8DE',
        black: '#354449',
        green2: '#4CAF50'
      },
    },
  },
  icons: {
    values: {
      wordfence: {
        component: Wordfence,
      },
      managewp: {
        component: ManageWP,
      },
      mcafee: {
        component: McAfee,
      },
      norton: {
        component: Norton,
      },
      virustotal: {
        component: VirusTotal,
      },
      sucuri: {
        component: Sucuri,
      },
      gtmetrix: {
        component: GTMetrix,
      },
      lighthouse: {
        component: Lighthouse,
      },
      wave: {
        component: Wave,
      },
      google: {
        component: Google,
      },
      pagespeed: {
        component: PageSpeed,
      },
      yslow: {
        component: YSlow,
      },
    },
  },
}

export default new Vuetify(opts)

<template>
  <div>
    <v-page-title title="Invoices">
    </v-page-title>
    <v-card max-width="1200" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-zen line-one="Achieve" line-two="Wordpress" line-three="Zen"></v-zen>
          </v-col>
          <v-col>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Invoices.vue',
  }
</script>

<style lang="scss" scoped>

</style>
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/user-meta';
const endpoints = {
  get:              model+'/get',
  byScan:           model+'/by-scan',
  toggleLogo:       model+'/toggle/logo',
  update:           model+'/update',
  updateCycle:      model+'/update/cycle',
  updateTour:       model+'/update/tour',
  updateBranding:   model+'/update/branding'
}
const mutation_calls = {
  get:              'SET_GET',
  byScan:           'SET_BYSCAN',
  toggleLogo:       'SET_TOGGLELOGO',
  update:           'SET_UPDATE',
  updateCycle:      'SET_UPDATECYCLE',
  updateTour:       'SET_UPDATETOUR',
  updateBranding:   'SET_BRANDING',
  reset:            'SET_RESET',
}

const initialState = () => ({
  get: null,
});

const state = initialState();

// getters
const getters = {
  get: state => state.get,
}

// actions
const actions = {
  async get(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      let apiParams = { 
        'user_id': user.id
      };
      apiService.post(endpoints.get, apiParams)
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.get, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("meta.get.status.error"), { root: true })
        reject(error);
      });
    });
  },
  byScan(context, fields) {
    return new Promise((resolve, reject) => {
      let apiParams = { 
        'scan_id': fields.id
      };
      apiService.post(endpoints.byScan, apiParams)
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.byScan, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("meta.byScan.status.error"), { root: true })
        reject(error);
      });
    });
  },
  toggleLogo(context) {
    let user = context.rootGetters['users/user'];
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.toggleLogo, {
        'user_id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.toggleLogo);
        context.dispatch('system/setMessage', i18n.t("meta.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("meta.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  update(context, fields) {
    let user = context.rootGetters['users/user'];
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.update, {
        'footer_disclaimer': fields.footer_disclaimer,
        'user_id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.update, fields);
        context.dispatch('system/setMessage', i18n.t("meta.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("meta.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  updateCycle(context, fields) {
    let user = context.rootGetters['users/user'];
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.updateCycle, {
        'cycle': fields.cycle,
        'user_id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.updateCycle, fields);
        context.dispatch('system/setMessage', i18n.t("meta.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("meta.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  updateTour(context, fields) {
    let user = context.rootGetters['users/user'];
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.updateTour, {
        'user_id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.updateTour, fields);
        context.dispatch('system/setMessage', i18n.t("meta.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("meta.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  updateBranding(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.updateBranding, {
        'branding': fields.branding,
        'user_id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit('users/SET_BRANDING', fields.branding, { root: true })
        context.commit(mutation_calls.updateBranding, fields.branding);
        context.dispatch('system/setMessage', i18n.t("meta.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("meta.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
}

// mutations
const mutations = {
  SET_GET(state, payload) {
    state.get = payload;
  },  
  SET_BYSCAN(state, payload) {
    state.get = payload;
  },  
  SET_TOGGLELOGO(state) {
    state.get.hide_logo = (state.get.hide_logo == 1) ? 0 : 1;
  },
  SET_UPDATE(state, payload) {
    state.get.footer_disclaimer = payload.footer_disclaimer;
  },
  SET_UPDATECYCLE(state, payload) {
    state.get.cycle = payload.cycle;
  },
  SET_UPDATETOUR(state, payload) {
    state.get.initial_tour = payload.initial_tour;
  },
  SET_BRANDING(state, payload) {
    state.get.branding = payload;
  },
  SET_RESET(state) {
    Object.assign(state, initialState());
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
<template>
  <div class="theme-fullscreen">
    <v-modal-support v-model="dialogSupport"></v-modal-support>
    <v-system-message></v-system-message>
    <v-main>
      <v-fade-transition name="fade" mode="out-in">
        <v-container fluid class="pa-0">
          <v-row dense no-gutters>
            <v-col sm="12" md="6" class="relative d-none d-md-flex">
              <v-img
                :src="getRandomImage()"
                class="white--text align-end black"
                height="100vh"
                width="100%"
                gradient="to top right, rgba(0,0,0,0.1), rgba(0,0,0,0.6)"
              >
                <v-headline-carousel :sentences="sentences" :show-icons="true"></v-headline-carousel>
                <v-banner absolute bottom class="text-end primary--text text--darken text-caption">
                  Photo by <a href="https://unsplash.com/@santesson89?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Andrea De Santis</a> on <a href="https://unsplash.com/photos/a-set-of-stairs-leading-up-to-a-window-IVgWh0Qwk08?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                </v-banner>
                <v-btn small absolute bottom left color="white" light @click.prevent="dialogSupport = true">
                  Contact Support
                </v-btn>
              </v-img>
            </v-col>
            <v-col sm="12" md="6" class="relative">
              <router-view :class="$utilities.appClass(this.$route)" />
            </v-col>
          </v-row>
        </v-container>
      </v-fade-transition>
    </v-main>
  </div>
</template>

<script>
  export default {
    name: 'Fullscreen.vue',
    methods: {
      getRandomImage() {
        const images = [
          'andrea-de-santis-YxQB7coLoGA-unsplash.jpg',
          'andrea-de-santis-90GttSM8gyI-unsplash.jpg', 
          'andrea-de-santis-IVgWh0Qwk08-unsplash.jpg'
        ];
        const randomIndex = Math.floor(Math.random() * images.length);
        return require(`@/assets/photos/${images[randomIndex]}`);
      },
    },
    data() {
      return {
        dialogSupport: false,
        sentences: [
          {
            content: 'Unleash the power of secure web monitoring.',
            icon: 'mdi-monitor-dashboard'
          },
          {
            content: 'Join Hounddog and keep your websites in top shape.',
            icon: 'mdi-heart-flash'
          },
          {
            content: 'Track, scan, and protect—all in one place.',
            icon: 'mdi-toolbox'
          },
          {
            content: 'Your websites deserve the Hounddog advantage.',
            icon: 'mdi-thumb-up'
          }
        ],
      }
    },
  };
</script>

<style lang="scss">
  .theme-fullscreen{
    height: 100vh;
    .v-main{
      height: 100vh;
      padding: 0 !important;
      .toolbar{
        position: absolute;
        top: 20px;
        left: 20px;
        right: 20px;
        text-align: center;
        z-index: 100;
      }
    }
    .login,
    .logout,
    .register,
    .password{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      .v-form{
        width: 100%;
      }
    }
  }
</style>
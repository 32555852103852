<template>
  <div id="component-scan">
    <v-dialog v-model="display" max-width="800px" persistent rounded>
      <v-form ref="form" v-model="form.valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">New Scan</span>
            <v-btn small dark fab absolute right text @click="onModalClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" class="px-5 pt-5">
                <label for="label-site" class="d-block text-left mb-1 font-weight-bold black--text"> Choose a Site </label>
                <v-combobox
                  id="site-combobox"
                  placeholder="Select one or Enter a URL (ex. https://domain.com)"
                  dense
                  outlined
                  clearable
                  required
                  v-model="form.fields.site_name"
                  :return-object="true"
                  :error="!!error"
                  :error-messages="error"
                  :items="sites"
                  item-value="name"
                  item-text="name"
                  @change="onSiteChanged" />
              </v-col>
              <v-col cols="12" class="px-5">
                <label for="label-report-type" class="d-block text-left mb-1 font-weight-bold black--text"> Report Type </label>
                <v-radio-group row v-model="form.fields.report_label">
                  <v-radio v-for="row in reportTypes" :key="row" :label="row" :value="row" />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col align="center">
                <v-expansion-panels class="expander" v-model="defaultAccordion">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="ex-header">
                      <label for="label-customize" class="d-block text-left mb-1 font-weight-bold black--text"> Customization Options </label>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content accordion>
                      <v-row dense class="pt-5">
                        <v-col cols="6">
                          <label for="label-cover" class="d-block text-left mb-1 mt-2 font-weight-bold black--text" hide-details>
                            Cover Page Label
                          </label>
                          <v-text-field v-model="form.fields.report_label" outlined dense hide-details />
                        </v-col>
                        <v-col cols="6">
                          <label for="label-theme" class="d-block text-left mb-1 mt-2 font-weight-bold black--text" hide-details> Theme </label>
                          <v-select
                            v-model="form.fields.report_theme"
                            :items="$strings.themeOptions()"
                            item-value="value"
                            item-text="label"
                            dense
                            outlined
                            hide-details />
                        </v-col>
                      </v-row>
                      <v-divider class="my-5"></v-divider>
                      <v-row dense>
                        <v-col cols="12" align="left">
                          <v-report-selector v-model="reportOption" :id="form.fields.client_id" />
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="mx-auto py-5">
              <v-btn dark color="blue-grey darken-4" @click="startScan"> Start Scan </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Scan.vue",
  data() {
    return {
      defaultFields: {
        report_label: "Monthly Maintenance",
        report_theme: "default",
        output: "pdf",
        logo: "",
        client_id: "0",
        hide_logo: false,
        site_name: "",
        newSiteURL: "",
        scanList: [],
      },
      reportTypes: ["Monthly Maintenance", "Full-site Audit"],
      form: {
        valid: false,
        fields: {},
      },
      error: "",
      defaultAccordion: 0,
      reportOption: []
    };
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    user() {
      return this.$store.getters["users/user"];
    },
    meta() {
      return this.$store.getters["userMeta/get"];
    },
    sites() {
      return this.$store.getters["sites/all"];
    }
  },
  methods: {
    isUrlValid(value) {
      const pattern = /^(https?:\/\/)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+.*$/;
      return pattern.test(value);
    },
    isDataValid() {
      if (Number(this.form.fields.client_id) === 0) {
        return this.isUrlValid(this.form.fields.site_name);
      }
      return true;
    },
    onSiteChanged(value) {
      this.error = "";

      if (!value) return;

      if (typeof value === "object") {
        this.form.fields.client_id = value.id;
        this.form.fields.site_name = value.name;
        this.reportOption = value.report_list ?? [];
        this.form.fields.scanList = [];
      }

      if (typeof value === "string") {
        const valid = this.isUrlValid(value);
        if (!valid) {
          this.error = "Please enter a valid URL (ex. https://domain.com) or valid site from list.";
          return;
        }
        this.form.fields.client_id = 0;
        this.form.fields.site_name = value;
        this.reportOption = [];
        this.form.fields.scanList = [];
      }
    },
    async startScan() {
      this.form.valid = this.isDataValid();
      if (!this.form.valid) {
        this.error = "Please enter a valid URL (ex. https://domain.com) or valid site from list.";
        return;
      }
      if (this.reportOption.length > 0) {
        this.form.fields.scanList = this.reportOption;
        this.$emit("scan", this.form.fields);
      }
    },
    onModalClose() {
      this.$emit("close", null);
    },
  },
  watch: {
    display(newValue) {
      if (newValue) {
        this.form.fields = {
          report_label: "Monthly Maintenance",
          report_theme: "default",
          output: "pdf",
          logo: "",
          client_id: "0",
          hide_logo: false,
          site_name: "",
          newSiteURL: "",
          scanList: this.reportOption.map((r) => r.name),
        };
      }
    },
  },
};
</script>

<style scoped>
.expander {
  border-radius: 0;
}
.ex-header {
  background-color: #eee !important;
}
ul {
  list-style: none;
  padding-left: 0;
}
.box {
  border: 1px solid #9e9e9e;
  border-radius: 3px;
  min-height: 40px;
  padding: 5px;
  width: 100%;
}
.box-list {
  max-height: 200px;
  overflow: hidden;
  padding: 0;
}
.box-checkbox >>> .v-icon {
  font-size: 20px;
}
.box-checkbox >>> .v-label {
  font-size: 12px;
  font-family: sans-serif;
  color: #111;
}
.v-progress-circular {
  margin: 1rem;
}
.v-expansion-panel:before {
  box-shadow: none !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>

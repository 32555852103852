import Vue            from 'vue'
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/posts/categories';
const endpoints = {
  all:              model+'/all',
  get:              model+'/get',
  insert:           model+'/insert',
  update:           model+'/update',
}
const mutation_calls = {
  all:              'SET_ALL',
  get:              'SET_GET',
  insert:           'SET_INSERT',
  update:           'SET_UPDATE',
}

const state = () => ({
  all: null,
  get: null,
})

const getters = {
  all: state => state.all,
  get: state => state.get,
}

const actions = {
  all(context, payload) {
    return new Promise((resolve, reject) => {
      if(context.rootGetters['postsCategories/all'] != null && !payload.force){
        return;
      }
      apiService.post(endpoints.all,{})
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("categories.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async get(context, fields) {
    return new Promise((resolve, reject) => {
      if(fields.id == 0){
        return resolve();
      }
      let apiParams = { 
        'id': fields.id
      };
      apiService.post(endpoints.get, apiParams)
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.get, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("categories.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  insert(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.insert, {
        'name': fields.name,
        'active': fields.active,
      })
      .then(response => {
        let payload = response.data[0];
        context.commit(mutation_calls.insert, payload);
        context.dispatch('system/setMessage', i18n.t("categories.insert.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("categories.insert.status.error"), { root: true })
        reject(error);
      });
    });
  },
  update(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.update, {
        'name': fields.name,
        'active': fields.active,
        'id': fields.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.update, fields);
        context.dispatch('system/setMessage', i18n.t("categories.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("categories.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
}

const mutations = {
  SET_ALL(state, payload) {
    state.all = payload.sort((t1,t2) => t1.id > t2.id ? -1 : 1);
  },
  SET_GET(state, payload) {
    state.get = payload;
  },  
  SET_INSERT(state, payload) {
    state.all.push(payload);
  },
  SET_UPDATE(state, payload) {
    Vue.set(state.all, payload.index, payload)
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/integrations';
const endpoints = {
  get:              model+'/get',
  upsert:           model+'/upsert',
}
const mutation_calls = {
  get:              'SET_GET',
  upsert:           'SET_UPSERT',
}

const state = () => ({
  get: null,
})

const getters = {
  get: state => state.get,
}

const actions = {
  async get(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.get, {
        'user_id': user.id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.get, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
  async upsert(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.upsert, {
        'slack': fields.slack,
        'gtmetrix': fields.gtmetrix,
        'managewp': fields.managewp,
        'uptimerobot': fields.uptimerobot,
        'mainwp': fields.mainwp,
        'infinitewp': fields.infinitewp,
        'user_id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.upsert, fields);
        context.dispatch('system/setMessage', i18n.t("integrations.upsert.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("integrations.upsert.status.error"), { root: true })
        reject(error);
      });
    });
  },
}

const mutations = {
  SET_GET(state, payload) {
    state.get = payload;
  },  
  SET_UPSERT(state, payload) {
    state.get = payload;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
<template>
  <v-card max-width="1200px" class="mx-auto my-5" :class="{'slideUp': !show}" elevation="0">
    <v-btn icon @click="close" absolute top right>
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-container class="pa-5">
      <v-row dense align="start">
        <v-col cols="auto" class="mr-5">
          <slot name="icon"></slot>
        </v-col>
        <v-col>
          <slot name="title"></slot>
          <slot name="content"></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  export default {
    name: 'Help.vue',
    data() {
      return {
        show: true
      }
    },
    created(){
      this.show = !this.$utilities.getCookie('hideHelp');
    },
    methods: {
      close() {
        this.$utilities.setCookie('hideHelp', true, 3000);
        this.show = false;
      }
    }
  }
</script>

<style>
  .help{

  }
</style>
<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
    export default {
        name: 'App.vue',
    }
</script>

<style lang="scss">
    @import '@/main.scss';
</style>

<template>
  <div>
    <v-card max-width="1200" class="mx-auto" elevation="0" color="transparent">
      <v-container>
        <v-row dense class="my-4">
          <v-col class="text-center">
            <h1>Your order was cancelled!</h1>
            <br><br>
            <b>Your current credit count:</b>
            <v-sheet class="rounded-pill mx-auto teal pa-2" elevation="5" width="200px" v-if="credits">
              <h1 class="text-h3 white--text mb-0">{{credits.remaining_credits}}</h1>
            </v-sheet>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Cancel.vue',
    data() {
      return {
      }
    },
    computed: {
      credits(){
        return this.$store.getters['transactions/credits'];
      },
    },
    async created(){
      let self = this;
      await this.$store.dispatch('transactions/delete', {
        token: self.$route.params.token
      })
      .then(() => {
        setTimeout(() =>{
          self.$router.push({name: 'Billing'});
        },5000)
      });
    }
  }
</script>

<style lang="scss">
</style>
<template>
  <div>
    <v-page-title title="Welcome">
      <v-btn
        to="#support"
        elevation="0"
        color="blue-grey darken-4"
        small
        dark>
        Support Ticket
      </v-btn>
    </v-page-title>

    <v-tour v-if="meta && meta.initial_tour == 0" name="initialTour" :steps="steps" :options="{ highlight: true }" :callbacks="tourCallbacks"></v-tour>

    <v-card max-width="1200" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-zen class="mb-5" line-one="Achieve" line-two="Wordpress" line-three="Zen"></v-zen>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-card elevation="0" outlined style="height: 100%;" class="pa-2" id="v-step-0">
                  <v-card-text style="height: 100%;">
                    <h3 class="text-h5 mb-0 font-weight-bold secondary--text">Hounddog Dashboard</h3>
                    <h4 class="text-h6 mb-2 font-weight-bold black--text">An all-in-one command center for maintaining peak website performance and security.</h4>
                    <p> The Hounddog app provides you with real-time insights, easy-to-read reports, and actionable recommendations to safeguard your digital assets. Easily manage your sites, monitor their health, and track performance metrics in one streamlined interface. With Hounddog’s robust scanning capabilities, you’ll be alerted to potential vulnerabilities and optimization opportunities, ensuring your site remains secure, fast, and reliable. Whether you’re looking to enhance security protocols, boost site speed, or keep your plugins and themes up-to-date, your Hounddog dashboard is here to guide you every step of the way. Take control of your website’s well-being and enjoy peace of mind knowing Hounddog has your back.</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-account-info class="mb-5"></v-account-info>
              </v-col>
              <v-col>
                <v-user-info class="mb-5"></v-user-info>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-wp-security-news></v-wp-security-news>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Home.vue',
    data() {
      return {
        tourCallbacks: {
          onSkip: this.tourEndCallback,
          onFinish: this.tourEndCallback
        },
        steps: [
          {
            target: '#v-step-0',
            content: `Welcome to Hounddog and thank you for signing up!`,
          },
          {
            target: '#tab-sites',
            content: 'Start here by adding your first site.'
          },
          {
            target: '#tab-scans',
            content: 'Once you have added a site, you can scan it for vulnerabilities and performance issues.'
          },
          {
            target: '#tab-emails',
            content: 'You can also manage your email settings here. These would be templates to send to your clients.'
          },
          {
            target: '#tab-invoices',
            content: 'You can send your clients an invoice from here for maintenance services rendered.'
          },
          {
            target: '#tab-knowledge-base',
            content: 'You can search the knowledge base for answers to common questions and discuss Wordpress related plugins and themes.'
          },
        ]
      }
    },
    computed:{
      meta() {
        return this.$store.getters["userMeta/get"];
      },
    },
    created () {
      if(this.meta && this.meta.initial_tour == 0 && this.$tours['initialTour']){
        this.$tours['initialTour'].start();
      }
    },
    methods: {
      tourEndCallback() {
        this.$store.dispatch("userMeta/updateTour", {});
      },
    }
  }
</script>

<style lang="scss">
</style>

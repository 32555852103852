import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/gtmetrix';
const endpoints = {
  get:              model+'/get',
  upsert:           model+'/upsert',
  delete:           model+'/delete'
}
const mutation_calls = {
  get:              'SET_GET',
  upsert:           'SET_UPSERT',
  delete:           'SET_DELETE'
}

const state = () => ({
  get: null,
})

const getters = {
  get: state => state.get,
}

const actions = {
  async get(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.get, {
        'user_id': user.id
      })
      .then(response => {
        let payload = response.data;
        delete response.data.id;
        context.commit(mutation_calls.get, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
  async insert(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.insert, {
        'url': fields.url,
        'email': fields.email,
        'key': fields.key,
        'user_id': user.id
      })
        .then(response => {
          let payload = response;
          context.commit(mutation_calls.insert, fields);
          context.dispatch('system/setMessage', i18n.t("gtmetrix.insert.status.success"), { root: true })
          resolve(payload);
        })
        .catch(error => {
          context.dispatch('system/setMessage', i18n.t("gtmetrix.insert.status.error"), { root: true })
          reject(error);
        });
    });
  },
  async update(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.update, {
        'url': fields.url,
        'email': fields.email,
        'key': fields.key,
        'user_id': user.id
      })
        .then(response => {
          let payload = response;
          context.commit(mutation_calls.update, fields);
          context.dispatch('system/setMessage', i18n.t("gtmetrix.update.status.success"), { root: true })
          resolve(payload);
        })
        .catch(error => {
          context.dispatch('system/setMessage', i18n.t("gtmetrix.update.status.error"), { root: true })
          reject(error);
        });
    });
  },
  async upsert(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.upsert, {
        'url': fields.url,
        'email': fields.email,
        'key': fields.key,
        'user_id': user.id
      })
        .then(response => {
          let payload = response;
          context.commit(mutation_calls.upsert, fields);
          context.dispatch('system/setMessage', i18n.t("gtmetrix.update.status.success"), { root: true })
          resolve(payload);
        })
        .catch(error => {
          context.dispatch('system/setMessage', i18n.t("gtmetrix.update.status.error"), { root: true })
          reject(error);
        });
    });
  },
  delete(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.delete, {
        'user_id': user.id
      })
      .then( response => {
        let payload = response;
        context.commit(mutation_calls.delete, user.id);
        context.dispatch('system/setMessage', i18n.t("gtmetrix.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("gtmetrix.delete.status.error"), { root: true })
        reject(error);
      });
    });
  },
}

const mutations = {
  SET_GET(state, payload) {
    state.get = payload;
  },  
  SET_INSERT() {
  },
  SET_UPDATE() {
  },
  SET_UPSERT(state, payload) {
    state.get = payload;
  },
  SET_DELETE() {
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
<template>
  <v-card elevation="0" outlined>
    <v-card-title>User Info</v-card-title>
    <v-card-text class="pa-0">
      <v-simple-table v-if="user" class="no-hover">
        <template v-slot:default>
          <tbody>
            <tr>
              <th width="40%">ID</th>
              <td>{{user.id}}</td> 
            </tr>  
            <tr>
              <th>Name</th>
              <td>{{user.name}}</td> 
            </tr>  
            <tr>
              <th>Email</th>
              <td>{{user.email}}</td> 
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'UserInfo.vue',
    data() {
      return {

      }
    },
    computed:{
      user(){
        return this.$store.getters['users/user'];
      },
    }
  }
</script>

<style>

</style>
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/user';

const endpoints = {
  all:              model+'/all-logs',
}
const mutation_calls = {
  all:              'SET_ALL',
}

const state = () => ({
  all: null,
})

const getters = {
  all: state => state.all,
}

const mutations = {
  SET_ALL(state, payload) {
    state.all = payload.sort((t1,t2) => t1.id > t2.id ? -1 : 1);
  }, 
}

const actions = {
  all(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      if(context.rootGetters['logs/all'] != null){
        return;
      }
      apiService.post(endpoints.all,{
        'email': user.email,
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      }, error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
<template>
  <v-dialog v-model="value" max-width="60%" persistent>
    <v-form ref="form" v-model="form.valid">
      <v-card>
        <v-card-title>
          <span class="text-h5">Support Ticket</span>
          <v-btn small dark fab absolute right text @click.prevent="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-expansion-panels class="mb-5">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Your System Information
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-col>
                      <table>
                        <tr>
                          <td>Browser CodeName:</td>
                          <td>{{winDetails.appCodeName}}</td>
                        </tr>
                        <tr>
                          <td>Browser Name:</td>
                          <td>{{winDetails.appName}}</td>
                        </tr>
                        <tr>
                          <td>Browser Version:</td>
                          <td>{{winDetails.appVersion}}</td>
                        </tr>
                        <tr>
                          <td>Cookies Enabled:</td>
                          <td>{{winDetails.cookieEnabled}}</td>
                        </tr>
                        <tr>
                          <td>Browser Language:</td>
                          <td>{{winDetails.language}}</td>
                        </tr>
                        <tr>
                          <td>Browser Online:</td>
                          <td>{{winDetails.onLine}}</td>
                        </tr>
                        <tr>
                          <td>Platform:</td>
                          <td>{{winDetails.platform}}</td>
                        </tr>
                        <tr>
                          <td>User-agent header:</td>
                          <td>{{winDetails.userAgent}}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.fields.name"
                  type="text"
                  :readonly="!!user"
                  outlined
                  dense
                  required
                  placeholder="Name"
                  @keydown.enter.prevent
                  label="Name"
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Subject"
                  outlined
                  v-model="form.fields.subject"
                  :items="$strings.supportSubject()"
                  item-value="label"
                  item-text="label"
                  :rules="[$utilities.rules.required]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="form.fields.message"
                  outlined
                  dense
                  required
                  placeholder="Message"
                  label="Message"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn dark color="blue-grey darken-4" @click="save">Send</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'Support.vue',
  data() {
    return {
      notifications: false,
      winDetails: window.navigator,
      form:{
        valid: false,
        fields:{
          id: '',
          name: '',
          subject:'',
          message: '',
        }
      }
    }
  },
  emits: ['update:modelValue'],
  props:{
    value: {
      type: Boolean,
      default: false,
    },
  },
  created(){
    if(this.user){
      this.form.fields.id = this.user.id;
      this.form.fields.name = this.user.name;
    }
  },
  computed:{
    user(){
      return this.$store.getters['users/user'];
    },
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    save(){
      let self = this;
      if(this.$refs.form.validate()){
        this.$store.dispatch("support/insert", this.form.fields)
          .then(() => {
            this.systemMessage('Your message delivered successfully!');
            self.updateValue(false);
          });
      }else{
        this.systemMessage('Please validate the form!');
      }
    },
  },
  watch: {
    '$route'(to) {
      if(to.hash == '#support'){
        this.updateValue(true);
      }
    },
    value(to){
      if(!to){
        this.$router.go(-1);
        history.pushState(null, null, ' ');
        this.form.fields.subject = '';
        this.form.fields.message = '';
      }
    }
  }
}
</script>

<style>

</style>
<template>
  <div class="page-title">
    <v-sheet class="mx-auto pt-2 pb-0" elevation="0" color="rgba(0, 0, 0, .2)" light centered min-height="58px">
      <v-card max-width="1160px" class="mx-auto height-58 pa-0" elevation="0" color="transparent">
        <v-container class="height-58 pa-0">
          <v-row dense align="center" class="height-58 pa-0">
            <template v-if="title">
              <v-col cols="6" class="text-start pa-0">
                <div class="ma-0 text-body-1 blue-grey--text text--darken-4 text-uppercase font-weight-bold">{{title}}</div>
              </v-col>
              <v-col cols="6" class="text-end pa-0">
                <slot></slot>
              </v-col>
            </template>
            <template v-if="!title">
              <v-col class="text-center pa-0">
                <slot></slot>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
  export default {
    name: 'PageTitle.vue',
    data() {
      return {
      }
    },
    props:{
      title: {
        type: String,
        default: '',
      },
      icon: {
        type: [String, Boolean],
        default: false,
      },
    },
    async created(){

    },
  }
</script>

<style lang="scss" scoped>
  .page-title{

  }
  .height-58{
    height: 58px;
  }
</style>
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/managewp';
const endpoints = {
  get:              model+'/get',
  exists:           model+'/exists',
  upsert:           model+'/upsert',
  delete:           model+'/delete',
}
const mutation_calls = {
  get:              'SET_GET',
  exists:           'SET_EXISTS',
  upsert:           'SET_UPDATE',
  delete:           'SET_DELETE',
}

const state = () => ({
})

const getters = {
}

const actions = {
  async get(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.get, {
        'client_id': fields.client_id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.get, fields);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
  async exists(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.exists, {
        'client_id': fields.client_id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.exists, fields);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
  async upsert(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.upsert, {
        'mwp_id': fields.mwp_id,
        'client_id': fields.client_id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.upsert, fields);
        context.dispatch('system/setMessage', i18n.t("managewp.upsert.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("managewp.upsert.status.error"), { root: true })
        reject(error);
      });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post('/managewp/delete', {
        'client_id': fields.client_id
      })
      .then(response => {
        let payload = response;
        context.commit("SET_DELETE", fields);
        context.dispatch('system/setMessage', i18n.t("managewp.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("managewp.delete.status.error"), { root: true })
        reject(error);
      });
    });
  }
}

const mutations = {
  SET_GET() {
  },
  SET_UPDATE() {
  },
  SET_DELETE() {
  },
  SET_EXISTS() {
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
<template>
  <v-dialog v-model="value" max-width="800px" persistent>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">Slack</span>
          <v-spacer></v-spacer>
          <v-btn small dark fab right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <h4 class="text-center py-5">For more information about how to get this setup, please visit the official Slack <a href="https://api.slack.com/messaging/webhooks" target="_blank">documentation</a>.</h4>
          <v-container fluid>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="form.fields.webhook"
                  prepend-icon="mdi-lock"
                  :rules="[$utilities.rules.required]"
                  label="Webhook"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="form.fields.channel"
                  prepend-icon="mdi-lock"
                  :rules="[$utilities.rules.required]"
                  label="Channel"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="form.fields.username"
                  prepend-icon="mdi-lock"
                  :rules="[$utilities.rules.required]"
                  label="Username"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container> 
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn dark color="blue-grey darken-4" @click="save">
            Save
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>  
    </v-form>
  </v-dialog>
</template>

<script>
  export default {
    name: 'Slack.vue',
    data() {
      return {
        form: {
          valid: false,
          fields:{
            enable: 1,
            webhook: '',
            channel: '',
            username: '',
          }
        },
      }
    },
    emits: ['update:modelValue'],
    props:{
      value:{
        type: Boolean,
        default: false
      },
    },
    created(){
      this.get();
    },
    methods:{
      updateValue (value) {
        this.$emit('input', value);
      },
      async get(){
        let self = this;
        await this.$store.dispatch("slack/get")
          .then((data) => {
            self.form.fields = { ...data };
          });
      },
      async save () {
        if(this.$refs.form.validate()){
          this.$store.dispatch("slack/upsert", this.form.fields)
            .then(() => {
              this.systemMessage('Settings saved!');
              this.updateValue(false);
            });
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
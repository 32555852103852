<template>
  <svg version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 120 120" style="enable-background:new 0 0 120 120;" xml:space="preserve">
    <path fill="#008DC2" d="M44.7,78.6v21.5h8.7c1.8-8.5,2.9-16.1,3.7-22.7C54.3,77.4,50,77.7,44.7,78.6z"/>
    <path fill="#008DC2" d="M12.3,88.4c-0.1-0.4-0.2-0.8-0.2-1.2c1.5-0.7,3-1.4,4.4-2v-4.3c-1.7,0.7-3.4,1.5-5.1,2.3
      c-0.1-0.6-0.2-1.3-0.3-1.9c1.8-0.8,3.6-1.6,5.3-2.4V62.8h-1.5l2.5-5.8l2.4,5.8h-1.4v15.3c3.7-1.4,7.3-2.6,10.7-3.6V58.1h-1.5
      l2.7-6.6l2.6,6.6h-1.4v15.8c3.8-1,7.2-1.7,10.3-2.2V52.9h-2.1l3.5-7.4l3.4,7.4h-2v18.3c5.7-0.8,10.3-1.1,13.2-1.1
      c1-12.1,0.6-19.8,0.5-21.8c-0.6-0.7-1-1.6-1-2.5c0-0.2,0-0.5,0-0.5h-1.9L61,31.6v0.1V19.9v-0.1c0,0-22.4,13.4-47.5,14.2
      c0,0-8.8,32.8,1.5,66.1h1.4V86.6C15.1,87.1,13.7,87.7,12.3,88.4z"/>
    <path fill="#008DC2" d="M31.6,81.2V100h10.3V78.9C38.7,79.6,35.3,80.3,31.6,81.2z"/>
    <path fill="#008DC2" d="M18.6,85.7v14.4h10.6V81.9C25.8,82.9,22.2,84.2,18.6,85.7z"/>
    <path fill="#008DC2" d="M41.9,73.7c-3.2,0.5-6.6,1.3-10.3,2.2v4.2c3.7-1,7.2-1.7,10.3-2.2V73.7z"/>
    <path fill="#008DC2" d="M57.8,71.9c-2.9,0.1-7.4,0.3-13,1.2v4.2c5.3-0.8,9.7-1,12.6-1.1C57.5,74.7,57.6,73.3,57.8,71.9z"/>
    <path fill="#008DC2" d="M29.2,76.4c-3.4,1-7,2.2-10.7,3.6v4.2c3.7-1.5,7.3-2.7,10.7-3.7V76.4z"/>
    <path fill="#008DC2" d="M104,80.2c-3.6-1.5-7.2-2.6-10.7-3.6v4.2c3.4,1,7,2.2,10.7,3.7V80.2z"/>
    <path fill="#008DC2" d="M65.2,77.3c0.8,6.6,2,14.2,3.7,22.7h8.8V78.6C72.5,77.7,68.2,77.4,65.2,77.3z"/>
    <path fill="#008DC2" d="M90.9,75.8c-3.6-0.9-7.1-1.7-10.3-2.2v4.1c3,0.5,6.5,1.2,10.3,2.3V75.8z"/>
    <path fill="#008DC2" d="M106,100.2h1.2c1.2-3.9,2.2-7.8,2.9-11.7c-1.4-0.7-2.7-1.3-4.1-1.9V100.2L106,100.2z"/>
    <path fill="#008DC2" d="M80.6,79.1v21.1h10.3V81.4C87.2,80.3,83.8,79.6,80.6,79.1z"/>
    <path fill="#008DC2" d="M93.3,81.9v18.1H104V85.7C100.3,84.2,96.7,82.9,93.3,81.9z"/>
    <path fill="#008DC2" d="M66.9,45.3H65c0,0,0,0.3,0,0.5c0,1-0.4,1.8-0.9,2.5c-0.1,1.9-0.5,9.6,0.5,21.9c2.9,0.1,7.5,0.3,13.3,1.1V52.9
      h-2.1l3.4-7.4l3.5,7.4h-2v18.8c3,0.5,6.5,1.2,10.3,2.2V58.2h-1.5l2.6-6.6l2.7,6.6h-1.4v16.4c3.4,1,7,2.1,10.7,3.6V62.9h-1.4
      l2.4-5.8l2.5,5.8h-1.4v16c1.7,0.7,3.5,1.5,5.2,2.3c3.2-25.8-2.5-47.3-2.5-47.3c-24.2-0.8-45.7-13.1-47.4-14.1v11.8L66.9,45.3z"/>
    <path fill="#008DC2" d="M77.8,73.2c-5.6-0.8-10.2-1.1-13.1-1.2c0.1,1.4,0.3,2.7,0.4,4.2c2.9,0.1,7.3,0.4,12.7,1.1V73.2L77.8,73.2
      L77.8,73.2z"/>
    <path fill="#008DC2" d="M106,85.2c1.4,0.6,2.9,1.3,4.3,2c0.2-1.3,0.4-2.7,0.6-4c-1.7-0.8-3.3-1.5-5-2.3L106,85.2L106,85.2z"/>
  </svg>
</template>

<script>
  export default {
    name: 'Wordfence.vue',
    props: {
      icon: {
        type: String,
        default: '$cancel'
      }
    }
  }
</script>
import Vue            from 'vue';
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/users';
const model_singular = '/user';

const endpoints = {
  all:              model+'/all',
  update:           model+'/update',
  insert:           model+'/insert',
  register:         model+'/register',
  login:            model_singular+'/login',
  account:          model_singular+'/update/account',
  passwordUpdate:   model_singular+'/update/password',
  passwordForgot:   model_singular+'/password/forgot',
  delete:           model_singular+'/delete',
  toggle:           model_singular+'/toggle',
  tokenUpdate:      model_singular+'/token/update',
  tokenExists:      model_singular+'/token/exists',
  byToken:          model_singular+'/token',
  invite:           model_singular+'/invite',
}
const mutation_calls = {
  all:              'SET_ALL',
  login:            'SET_LOGIN',
  logout:           'SET_LOGOUT',
  account:          'SET_ACCOUNT',
  password:         'SET_PASSWORD',
  insert:           'SET_INSERT',
  register:         'SET_REGISTER',
  update:           'SET_UPDATE',
  delete:           'SET_DELETE',
  toggle:           'SET_TOGGLE',
  branding:         'SET_BRANDING',
  reset:            'SET_RESET',
}

const initialState = () => ({
  all: null,
  user: JSON.parse(localStorage.getItem("user")) || null,
  auth: (localStorage.getItem("user") != null) || false,
});

const state = initialState();

// getters
const getters = {
  all: state => state.all,
  user: state => state.user,
  auth: state => state.auth
}

// actions
const actions = {
  all(context) {
    return new Promise((resolve, reject) => {
      let user = context.getters.user;
      if(context.rootGetters['users/all'] != null){
        return;
      }else{
        apiService.post(endpoints.all, {
          'id': user.id
        })
        .then(response => {
          let payload = response.data;
          context.commit(mutation_calls.all, payload);
          resolve(payload);
        })
        .catch(error => {
          context.dispatch('system/setMessage', i18n.t("users.all.status.error"), { root: true })
          reject(error);
        });
      }
    });
  },
  async login(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.login, {
        'email': fields.email,
        'password': fields.password,
      })
      .then(response => {
        let payload = response.data;
        if (payload.length == 0) {
          throw new Error('No account found');
        }
        context.commit(mutation_calls.login, payload);
        context.dispatch('system/setMessage', i18n.t("users.login.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.login.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async oauth(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.login, {
        'credential': fields.credential,
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.login, response.data);
        context.dispatch('system/setMessage', i18n.t("users.login.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.login.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async logout(context) {
    return new Promise((resolve) => {
      context.commit(mutation_calls.logout, null);
      resolve(false);
    });
  },
  update(context, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.update, {
        'name': payload.name,
        'email': payload.email,
        'id': payload.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.update, payload);
        context.dispatch('system/setMessage', i18n.t("users.account.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.account.status.error"), { root: true })
        reject(error);
      });
    });
  },
  accountUpdate(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.getters.user;
      apiService.post(endpoints.account, {
        'email': fields.email,
        'name': fields.name,
        'id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.account, fields);
        context.dispatch('system/setMessage', i18n.t("users.account.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.account.status.error"), { root: true })
        reject(error);
      });
    });
  },
  insert(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.insert, {
        'email': fields.email,
        'name': fields.name
      })
      .then(response => {
        let payload = response.data[0];
        context.commit(mutation_calls.insert, response.data[0]);
        context.dispatch('system/setMessage', i18n.t("users.insert.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.insert.status.error"), { root: true })
        reject(error);
      });
    });
  },
  register(context, fields) {
    return new Promise((resolve, reject) => {
      return apiService.post(endpoints.register, {
        'email': fields.email,
        'name': fields.name,
        'password': fields.password,
      })
      .then((response) => {
        let payload = response.data[0];
        context.commit(mutation_calls.register, response.data[0]);
        context.dispatch('system/setMessage', i18n.t("users.register.status.success"), { root: true });
        resolve(payload);
        // return true;
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.register.status.error"), { root: true })
        reject(error);
        // return false;
      });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.delete, { 'id': fields.id})
      .then((response) => {
        let payload = response;
        context.commit(mutation_calls.delete, fields.index);
        context.dispatch('system/setMessage', i18n.t("users.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.delete.status.error"), { root: true })
        reject(error);
      });
    });
  },
  toggle(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.toggle, {
        'id': fields.id,
      })
      .then(response => {
        let payload = response.data[0];
        let active = payload.active;
        context.commit(mutation_calls.toggle, {
          id: fields.id,
          active: active
        });
        if(active == 1){
          context.dispatch('system/setMessage', i18n.t("users.toggle.status.activated"), { root: true })
        }else{
          context.dispatch('system/setMessage', i18n.t("users.toggle.status.deactivated"), { root: true })
        }
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.toggle.status.error"), { root: true })
        reject(error);
      });
    });
  },
  passwordUpdate(context, fields) { //AUTH
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.passwordUpdate, {
        'password': fields.password,
        'id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.password, payload);
        context.dispatch('system/setMessage', i18n.t("users.password.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.password.status.error"), { root: true })
        reject(error);
      });
    });
  },
  passwordForgot(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.passwordForgot, {
        'password': fields.password,
        'token': fields.token
      })
      .then(response => {
        let payload = response;
        context.commit("SET_PASSWORD", payload);
        context.dispatch('system/setMessage', i18n.t("users.password.status.success"), { root: true });
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.password.status.error"), { root: true })
        reject(error);
      });
    });
  },
  passwordEmail(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.tokenUpdate, { 
        'email': fields.email
      })
      .then(response => {
        let payload = response;
        context.dispatch('system/setMessage', i18n.t("users.forgot.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.forgot.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async tokenExists(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.tokenExists, {
        'token': fields.token,
      })
      .then(response => {
        let payload = response;
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.tokenExists.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async byToken(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.byToken, {
        'token': fields.token,
      })
      .then(response => {
        let payload = response;
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.byToken.status.error"), { root: true })
        reject(error);
      });
    });
  },
  invite(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.invite, { 
        'id': fields.id
      })
      .then(response => {
        let payload = response;
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.invite.status.error"), { root: true })
        reject(error);
      });
    });
  },
  checkAuth(context){
    return context.getters.auth;
  },
}

// mutations
const mutations = {
  SET_ALL(state, payload) {
    if(payload && payload.length != 0){
      state.all = payload.sort((t1,t2) => t1.name < t2.name ? -1 : 1);
    }
  }, 
  SET_LOGIN(state, payload) {
    localStorage.setItem("user", JSON.stringify(payload));
    localStorage.setItem("token", payload.token);
    state.user = payload;
    state.auth = true;
  },
  SET_LOGOUT(state, payload) {
    localStorage.clear();
    state.user = payload;
    state.auth = false;
  },
  SET_ACCOUNT(state, fields) {
    state.user.email = fields.email;
    state.user.name = fields.name;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  SET_PASSWORD() {
  },
  SET_INSERT(state, payload) {
    state.all.push(payload);
  },
  SET_REGISTER() {
  },
  SET_UPDATE(state, payload) {
    let index = state.all.findIndex(user => user.id == payload.id);
    Vue.set(state.all, index, payload);
  },
  SET_DELETE(state, payload) {
    state.all.splice(payload, 1);
  },
  SET_TOGGLE(state, payload) {
    state.all = state.all.map(user => {
      if (user.id === payload.id) {
        user.active = payload.active;
      }
      return user;
    })
  },
  SET_BRANDING(state, payload) {
    state.user.branding = payload;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  SET_RESET(state) {
    Object.assign(state, initialState());
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
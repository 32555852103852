<template>
  <v-card v-if="show && results" class="pa-5 elevation-0">
    <v-container v-if="false">
      <v-row>
        <v-col align="center">
          <v-card elevation="0">
            <v-card-title>
              <v-row class="text-start p-0" align="center">
                <v-col>
                  <v-icon class="mr-2">$pagespeed</v-icon> PageSpeed
                </v-col>
                <v-col class="text-end">
                  <div class="text-caption"><b>Lighthouse Version</b> {{pageSpeedData.lighthouseResult.lighthouseVersion}}</div>
                  <div class="text-caption"><b>Emulator</b> {{pageSpeedData.lighthouseResult.configSettings.emulatedFormFactor}}</div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <div class="text-caption">Date: {{$date.fullDateTime(pageSpeedData.lighthouseResult.fetchTime)}}</div>
              <vue-gauge refid="gauge-1" :options="pageSpeedOptions"></vue-gauge>
              <v-alert outlined dense v-if="pageSpeedData.lighthouseResult.runWarnings.length > 0" type="info">{{pageSpeedData.lighthouseResult.runWarnings[0]}}</v-alert>
            </v-card-text>
          </v-card>
        </v-col>
        <v-divider vertical class="mx-4"></v-divider>
        <v-col align="center">
          <v-card elevation="0">
            <v-card-title>
              <v-row class="text-start p-0" align="center">
                <v-col>
                  <v-icon class="mr-2">$yslow</v-icon> YSlow
                </v-col>
                <v-col class="text-end">
                  <div class="text-caption"><b>Lighthouse Version</b> {{ySlowData}}</div>
                  <div class="text-caption"><b>Emulator</b> {{ySlowData}}</div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <div class="text-caption">Date: {{$date.fullDateTime(ySlowData)}}</div>
              <vue-gauge refid="gauge-2" :options="ySlowOptions"></vue-gauge>
              <v-alert outlined dense v-if="ySlowData" type="info">{{ySlowData}}</v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-img :src="results.data.lighthouseResult.audits['final-screenshot'].details.data" />
        </v-col>
        <v-col cols="6">
          <table>
            <tbody>
              <tr>
                <th>Captcha?</th>
                <td>{{results.data.captchaResult}}</td>
              </tr>
              <tr>
                <th>Time:</th>
                <td>{{$date.fullDateTime(results.data.lighthouseResult.fetchTime)}}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import VueGauge from 'vue-gauge';

  export default {
    name: 'Performance.vue',
    props:{
      show: {
        type: Boolean,
        default: false,
      },
      results: {
        type: Object,
        default() {
          return {}
        }
      },
    },
    components: { 
      VueGauge,
    },
    computed:{
      pageSpeedData(){
        return this.results.pageSpeed.data
      },
      ySlowData(){
        return this.results.ySlow.data
      },
      pageSpeedScore(){
        return 58;
      },
      ySlowScore(){
        return 90;
      },
      pageSpeedOptions(){
        return {
          'width': '100%',
          'needleStartValue': 0,
          'needleColor': '#004265',
          'arcLabels': ['20','40','60','80'],
          'arcColors': ['rgb(255,51,51)','rgb(204,102,51)','rgb(153,153,51)','rgb(102,204,51)','rgb(51,255,51)'],
          'needleValue': this.pageSpeedScore,
          'arcDelimiters': ['20','40','60','80'],
          'rangeLabel': ['0', '100'],
          'chartWidth': 300,
        }
      },
      ySlowOptions(){
        return {
          'width': '100%',
          'needleStartValue': 0,
          'needleColor': '#004265',
          'arcLabels': ['20','40','60','80'],
          'arcColors': ['rgb(255,51,51)','rgb(204,102,51)','rgb(153,153,51)','rgb(102,204,51)','rgb(51,255,51)'],
          'needleValue': this.ySlowScore,
          'arcDelimiters': ['20','40','60','80'],
          'rangeLabel': ['0', '100'],
          'chartWidth': 300,
        }
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>
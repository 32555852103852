<template>
  <v-card class="rounded-0" elevation="0" v-if="id">

    <v-card-text class="pa-0">
      <v-container fluid class="pa-0">
        <v-row class="pa-0">
          <v-col>
            <v-tabs v-model="tab">
              <v-tab>
                User Generated
              </v-tab>
              <v-tab>
                System Generated
              </v-tab>
              <!-- <v-tab>
                Initial Audit
                <v-icon>mdi-file-find</v-icon>
              </v-tab> -->
              <v-tabs-items v-model="tab">
                <v-tab-item class="py-5">
                  <v-card elevation="0" class="mb-5 mx-1">
                    <v-card-text class="pa-0">
                      <v-form ref="form" v-model="form.valid">
                        <v-row dense>
                          <v-col>
                            <v-text-field
                              width="100%"
                              v-model="form.fields.note"
                              outlined
                              placeholder="..."
                              @keydown.enter.prevent
                              hide-details
                              label="Create note"
                              append-icon="mdi-content-save"
                              @click:append="save"
                              :rules="[$utilities.rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>

                  <v-no-results message="No notes yet! Add one now!" v-if="items.length == 0"></v-no-results>
                  
                  <v-card class="mx-1" v-else>
                    <v-list dense color="yellow lighten-5">
                      <template v-for="(item, index) in items">
                        <v-list-item :key="index" class="py-2">
                          <v-list-item-content>
                            <v-list-item-title><b>{{ item.note }}</b></v-list-item-title>
                            <v-list-item-subtitle><span class="text-caption">{{$date.fullDateTime(item.created_at)}}</span></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <div v-if="readyToDelete == item.id">
                              <span class="text-caption py-2">Are you sure?</span>
                              <v-btn-toggle rounded background-color="transparent" class="ml-2">
                                <v-btn small color="white" @click="remove(index, item.id)">Yes</v-btn>
                                <v-btn small color="white" @click="readyToDelete = 0">No</v-btn>
                              </v-btn-toggle>
                            </div>
                            <v-btn x-small plain fab text right v-if="readyToDelete != item.id" @click="readyToDelete = item.id">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="index < items.length-1" :key="`divider-`+index"></v-divider>
                      </template>
                    </v-list>
                  </v-card>
                </v-tab-item>
                <v-tab-item class="py-5">
                  <v-card class="mx-1" elevation="0">
                    <v-no-results message="No system notes yet!"></v-no-results>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SiteNotes.vue',
  data() {
    return {
      tab: 0,
      defaultItem: {},
      items:[],
      readyToDelete: 0,
      form:{
        valid: false,
        fields:{
          note: '',
          source: '',
        }
      },
    }
  },
  async created(){
    this.all();
    this.form.fields.source = this.user.id;
  },
  computed:{
    id(){
      return this.$route.params.id;
    },
    user(){
      return this.$store.getters['users/user'];
    },
    notes(){
      return this.$store.getters['siteNotes/all'];
    },
    site(){
      return this.$store.state.sites.all.find(site => site.id === this.id);
    },
  },
  methods:{
    async all(){
      let self = this;
      await this.$store.dispatch("siteNotes/all", {'client_id': this.id})
        .then((data) => {
          self.items = data;
        });
    },
    async save(){
      if(this.$refs.form.validate()){
        let self = this;
        await this.$store.dispatch("siteNotes/upsert", { ...this.form.fields, ...{'client_id': this.id}})
          .then(() => {
            self.form.fields.note = '';
          });
      }else{
        this.systemMessage('Please enter a note!');
      }
    },
    async remove(index, id){
      await this.$store.dispatch("siteNotes/delete", {index: index, id: id});
    },
  },
  watch:{
    id(){
      this.all();
    }
  }
}
</script>

<style>

</style>
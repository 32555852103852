<template>
  <div>
    <v-page-title title="Additional Tests" />

    <v-card max-width="1200" class="mx-auto mt-5" elevation="0" color="transparent">
      <v-container>
        <v-row>
          <v-col cols="3">
            <h4 class="mb-2">Select or Enter a Site</h4>
            <v-combobox
              v-model="whichSite"
              :items="sites"
              item-value="url"
              item-text="name"
              dense
              outlined
              placeholder="Select a site or enter a site url"
            ></v-combobox>
            <v-divider class="mb-5"></v-divider>
            <h4 class="mb-2">Select Test</h4>
            <v-radio-group v-model="whichTest">
              <v-radio
                v-for="(item, index) in tests"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></v-radio>
            </v-radio-group>
            <v-divider class="mb-5"></v-divider>
            <h4 class="mb-2">Select Virus Scan</h4>
            <v-radio-group v-model="whichTest">
              <v-radio
                v-for="(item, index) in virusChecks"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></v-radio>
            </v-radio-group>
            <v-divider class="mb-5"></v-divider>
            <v-btn
              color="#354449"
              dark
              block
              :disabled="!whichTest || !whichSite"
              @click="startTest">Run Test</v-btn>
          </v-col>
          <v-col cols="9">

            <v-layout row wrap align-center text-center v-if="loading">
              <v-flex>
                <v-loader>Running test. Please wait...</v-loader>
              </v-flex>
            </v-layout>

            <div v-else>
              <v-card v-if="!run" class="elevation-0 gray-gradient rounded-xl" height="600px">
                <v-container bg fill-height grid-list-md text-xs-center>
                  <v-layout row wrap align-center text-center>
                    <v-flex>
                      <h3 class="nabla">This is a sandbox area</h3>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>

              <v-test-spelling :show="whichTest == 'spellcheck'" :results="results.spellcheck"></v-test-spelling>
              <v-test-clickjacking :show="whichTest == 'clickjacking'" :results="results.clickjacking" :headers="results.headers" :url="whichSite"></v-test-clickjacking>
              <v-test-accessibility :show="whichTest == 'accessibility'" :results="results.accessibility"></v-test-accessibility>
              <v-test-performance :show="whichTest == 'performance'" :results="results.performance"></v-test-performance>

              <v-test-download :show="whichTest == 'google'" :results="results.google" title="Google Safe Browsing - Download"></v-test-download>
              <v-test-download :show="whichTest == 'mcafee'" :results="results.mcafee" title="McAfee Site Advisor - Download"></v-test-download>
              <v-test-download :show="whichTest == 'norton'" :results="results.norton" title="Norton Safe Web - Download"></v-test-download>
              <v-test-download :show="whichTest == 'sucuri'" :results="results.sucuri" title="Sucuri - Download"></v-test-download>

            </div>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Tests.vue',
    data() {
      return {
        loading: false,
        whichTest: null,
        whichSite: null,
        run: false,
        results:{
          clickjacking: null,
          headers: null,
          spellcheck: null,
          accessibility: null,
          performance: null,
          google: null,
          mcafee: null,
          norton: null,
          sucuri: null,
        },
      }
    },
    computed: {
      sites(){
        return this.$store.getters['sites/all'];
      },
      tests(){
        return [
          { label: 'Clickjacking', value: 'clickjacking' }, 
          { label: 'Spellcheck', value: 'spellcheck' },
          { label: 'Accessibility', value: 'accessibility' },
          { label: 'Performance', value: 'performance' },
        ];
      },
      virusChecks(){
        return [
          { label: 'Google Safe Browsing', value: 'google' },
          { label: 'McAfee Site Advisor', value: 'mcafee' },
          { label: 'Norton Safe Web', value: 'norton' },
          { label: 'Sucuri', value: 'sucuri' },
          { label: 'Virus Total Scan', value: 'virustotal' },
        ];
      }
    },
    methods:{
      async startTest(){
        let self = this;
        this.loading = true;
        switch(this.whichTest){
          case 'clickjacking':
            await self.startClickjacking();
            break;
          case 'spellcheck':
            await self.startSpellcheck();
            break;
          case 'accessibility':
            await self.startAccessibilityTest();
            break;
          case 'performance':
            await self.startPerformanceTest();
            break;
          case 'google':
            await self.startGoogleTest();
            break;
          case 'mcafee':
            await self.startMcafeeTest();
            break;
          case 'norton':
            await self.startNortonTest();
            break;
          case 'sucuri':
            await self.startSucuriTest();
            break;
        }
        this.loading = false;
        this.run = true;
      },
      async startSpellcheck(){
        let self = this;
        this.results.spellcheck  = 'Loading...';
        await this.$store.dispatch("scans/spellcheck", {
          url: self.whichSite
        })
        .then((response) => {
          self.results.spellcheck = response.data;
        })
      },
      async startClickjacking(){
        let self = this;
        this.results.clickjacking  = 'Loading...';
        await this.$store.dispatch("scans/clickjacking", {
          url: self.whichSite
        })
        .then((response) => {
          self.results.clickjacking = response.data;
        });
        await this.$store.dispatch('scans/sitetrust', {
          url: self.whichSite
        })
        .then((results) => { 
          self.results.headers = results;
        });
      },
      async startAccessibilityTest(){
        let self = this;
        this.results.accessibility  = 'Loading...';
        await this.$store.dispatch("scans/accessibility", {
          url: self.whichSite
        })
        .then((response) => {
          self.results.accessibility = response.data;
        })
      },
      async startPerformanceTest(){
        let self = this;
        this.results.performance  = 'Loading...';
        await this.$store.dispatch("scans/performance", {
          url: self.whichSite
        })
        .then((response) => {
          self.results.performance = response.data;
        })
      },
      async startGoogleTest(){
        let self = this;
        this.results.google  = 'Loading...';
        await this.$store.dispatch("scans/google", {
          url: self.whichSite
        })
        .then((response) => {
          self.results.google = response;
        })
      },
      async startMcafeeTest(){
        let self = this;
        this.results.mcafee  = 'Loading...';
        await this.$store.dispatch("scans/mcafee", {
          url: self.whichSite
        })
        .then((response) => {
          self.results.mcafee = response;
        })
      },
      async startNortonTest(){
        let self = this;
        this.results.norton  = 'Loading...';
        await this.$store.dispatch("scans/norton", {
          url: self.whichSite
        })
        .then((response) => {
          self.results.norton = response;
        })
      },
      async startSucuriTest(){
        let self = this;
        this.results.sucuri  = 'Loading...';
        await this.$store.dispatch("scans/sucuri", {
          url: self.whichSite
        })
        .then((response) => {
          self.results.sucuri = response;
        })
      },
    },
    watch:{
      whichSite(to){
        if(this.$utilities.isObject(to)){
          this.whichSite = to.url;
        }
      },
    }
  }
</script>

<style lang="scss">
</style>
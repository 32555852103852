<template>
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160"><path d="M27.06188,78.26172c2.83913,11.53054,7.59233,30.82919,12.93857,30.82919s10.09943-19.29865,12.93857-30.82919c5.07102-20.59837,10.31605-41.89808,27.06143-41.89808s21.99041,21.29972,27.06143,41.89808c2.83913,11.53054,7.59233,30.82919,12.93857,30.82919s10.09943-19.29865,12.93857-30.82919c3.78928-15.39151,7.68-31.16655,16.38872-38.16317C135.50759,16.13903,109.64688,0,80,0S24.49285,16.13903,10.67272,40.09854c8.70938,6.99485,12.59988,22.77077,16.38916,38.16317Z" fill="#4877b5"/><path d="M147.06188,81.73828c-5.07102,20.59837-10.31605,41.89808-27.06143,41.89808s-21.99041-21.29972-27.06143-41.89808c-2.83913-11.53054-7.59233-30.82919-12.93857-30.82919s-10.09943,19.29865-12.93857,30.82919c-5.07102,20.59837-10.31605,41.89808-27.06143,41.89808s-21.99041-21.29972-27.06143-41.89808c-2.07741-8.4375-5.182-21.00497-8.7915-27.15554C1.4715,62.57102,0,71.11151,0,80c0,44.18324,35.81721,80,80,80s80-35.81676,80-80c0-8.88849-1.4715-17.42898-4.14728-25.41637-3.60929,6.15146-6.71342,18.71804-8.79084,27.15465Z" fill="#4877b5"/></svg></template>

<script>
  export default {
    name: 'Wave.vue',
    props: {
      icon: {
        type: String,
        default: '$cancel'
      }
    }
  }
</script>
<template>
  <v-card v-if="show && results" class="pa-5">
    <v-card-title>{{title}}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-if="results.png"><v-btn block :href="results.png" target=_blank>PNG</v-btn></v-col>
        <v-col v-if="results.pdf"><v-btn block :href="results.pdf" target=_blank>PDF</v-btn></v-col>
        <v-col v-if="results.url"><v-btn block :href="results.url" target=_blank>Direct</v-btn></v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'Download.vue',
    props:{
      show: {
        type: Boolean,
        default: false,
      },
      title:{
        type: String,
        default: 'Download',
      },
      results: {
        type: Object,
        default() {
          return {}
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
<template>
  <v-dialog v-model="value" width="100%" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Raw Data</span>
        <v-spacer></v-spacer>
        <v-btn small dark fab right text @click="updateValue(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <pre>{{json}}</pre>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ScanDebug.vue',
  data() {
    return {

    }
  },
  emits: ['update:modelValue'],
  props:{
    value: {
      type: Boolean,
      default: false,
    },
    json: {
      default: {},
    },
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
  }
}
</script>

<style>

</style>
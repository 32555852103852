<template>
  <div>
    <v-modal-posts-insert v-model="dialogInsert"></v-modal-posts-insert>
    <v-modal-posts-delete v-model="dialogDelete" :id="selectedIndex"></v-modal-posts-delete>
    <v-modal-posts-update v-model="dialogUpdate" :id="selectedIndex"></v-modal-posts-update>

    <v-page-title title="Knowledge Base">
      <v-btn 
        elevation="0" 
        color="primary" 
        small 
        dark 
        @click="dialogInsert = true"
      >
        <v-icon left small>mdi-plus</v-icon>
        New Post
      </v-btn>
    </v-page-title>

    <v-help>
      <template #icon>
        <v-icon x-large color="primary">mdi-post-outline</v-icon>
      </template>
      <template #title>
        <h4 class="text-h6 mb-2 mt-0 font-weight-bold secondary--text">What is the Knowledge Base?</h4>
      </template>
      <template #content> 
        <div>
          The Knowledge Base is a place where you can ask questions, share your knowledge, and learn from others. It's a great way to get help and support from the Hounddog team and community. 🐶🚀
        </div>
      </template>
    </v-help>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0">
      <v-container>
        <v-row >
          <v-col>
            <v-loader v-if="!posts"></v-loader>
            <div class="hounddog-table" v-else>
              <v-data-table
                item-key="id"
                :headers="headers" 
                :items="posts" 
                :items-per-page=1000000
                no-data-text="No posts yet."
              >
                <template v-slot:item.icon="{}">
                  <v-icon color="#CFD8DC" class="pr-2">{{scanIcon}}</v-icon>
                </template>

                <template v-slot:item.title="{ item }">
                  <b>{{ item.title }}</b><br>
                </template>

                <template v-slot:item.created_at="{item }">
                  {{ $date.fullDateTime(item.created_at, 30) }}
                </template>

                <template v-slot:item.user_name="{item }">
                  <b>{{ item.name }}</b><br>
                </template>

                <template v-slot:item.responses="{item }">
                  <b>{{ item.total_responses }}</b><br>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn-toggle mandatory background-color="primary" class="ml-2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :to="`/post/${item.id}`" small color="primary" icon v-bind="attrs" v-on="on">
                          <v-icon small color="white">mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                      <span>View Post</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn  @click="deleteDialog(item)" small color="primary" icon v-bind="attrs" v-on="on">
                          <v-icon small color="white">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Post</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </template>
                
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'KBHome.vue',
    data() {
      return {
        scanIcon: "mdi-post-outline",
        showOnly:'all',
        showingOnlyFilter: [
          {id: 'all', label: 'All'},
          {id: 'active', label: 'All Active'},
          {id: 'deactivated', label: 'All Deactivated'}
        ],
        selectedIndex: 0,
        dialogInsert: false,
        dialogDelete: false,
        dialogUpdate: false,
      }
    },
    created(){

    },
    computed:{
      headers(){
        let ret = [
          { text: "", value: "icon", width: "40px" },
          { text: "Title", value: "title", divider: true},
          { text: "Date", value: "created_at", width: '250px', align:'center', divider: true},
          { text: "Author", value: "user_name", width: '150px', align:'center', divider: true},
          { text: "Responses", value: 'responses', width: '120px', align:'center', divider: true },
          { text: "Actions", value: 'actions', width: '120px', align:'center', divider: true },
        ];
        return ret;
      },
      posts(){
        return this.$store.getters['posts/all'];
      },
    },
    methods: {      
      updateDialog (item) {
        this.selectedIndex = item.id;
        this.dialogUpdate = true;
      },
      deleteDialog (item) {
        this.selectedIndex = item.id;
        this.dialogDelete = true;
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="fieldset-basic">
    <fieldset>
      <legend>{{title}} <slot name="action"></slot></legend>
      <slot name="button"></slot>
      <slot></slot>
    </fieldset>
  </div>
</template>

<script>
  export default {
    name: 'FieldsetBasic.vue',
    props:{
      title: {
        type: String,
      },
    }
  }
</script>

<style lang="scss" scoped>
  .fieldset-basic{
    fieldset{
      border-width: 1px;
      border-radius: 4px;
      padding: 0px 8px 10px;
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      border-color: rgba(0,0,0,0.38);
      > legend{
        border-radius: 4px;
        font:{
          family: "Manrope", sans-serif;
          size: 12px !important;
        }
        padding: 4px 4px;
        color: rgba(0,0,0,0.6);
        &.align-left{
          text-align: left;
        }
      }
    }
  }
</style>
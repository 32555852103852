<template>
  <v-card v-if="show && results" class="pa-5">
    <v-alert :type="results.status" v-if="results">{{results.message}}</v-alert>
    <v-tabs v-model="tab" background-color="#78909C" dark centered v-if="headers">
      <v-tab>Missing Headers</v-tab>
      <v-tab v-if="results && results.status == 'error'">Example</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-simple-table dense class="response-data">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" width="25%">Key</th>
                <th class="text-left">Notes</th>
                <th class="text-center" width="18%">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, key) in $strings.getMissingHeaders(headers.response_headers)" :key="`missing-`+key">
                <td>{{value.name}}</td>
                <td class="show-all">{{value.notes}}</td>
                <td class="text-center">
                  <v-chip v-if="value.required == 1" x-small class="ma-0" color="red" text-color="white">MISSING</v-chip>
                  <v-chip v-if="value.required == 2" x-small class="ma-0" color="orange" text-color="white">WARNING</v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>
      <v-tab-item>
        <p class="text-caption text-center mt-4 mb-0">Click the opaque red box!</p>
        <v-slider
          v-model="decoyOpacity"
          label="Button opacity"
          hint="iFrame Opacity"
          step="0.1"
          max="1"
          min="0"
          class="mx-4"
        ></v-slider>
        <div class="clickjacking-results">
          <button class="decoy" :style="{opacity: decoyOpacity}" @click="decoyAction">
            Hidden clickable action
          </button>
          <iframe :src="url"></iframe>
        </div>
      </v-tab-item>
    </v-tabs-items>

  </v-card>
</template>

<script>
  export default {
    name: 'Clickjacking.vue',
    data() {
      return {
        tab: 0,
        decoyOpacity: 0.1,
      }
    },
    props:{
      show: {
        type: Boolean,
        default: false,
      },
      url: {
        type: String,
        default: '',
      },
      results: {
        type: Object,
        default() {
          return {}
        }
      },
      headers: {
        type: Object,
        default() {
          return {}
        }
      },
    },
    created(){

    },
    methods:{
      decoyAction(){
        alert('Some shady action!')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .clickjacking-results{
    position:relative;
    padding: 10px;
    iframe {
      position:relative;
      width:100%;
      height:400px;
      z-index:2;
      border: solid 1px #666;
    }
    .decoy {
      position:absolute;
      top: 10px;
      right: 10px;
      left: 10px;
      height:120px;
      z-index:3;
      background-color: red;
      border: solid 1px #000;
    }
  }
</style>
import axios from 'axios'
import router from '@/router'

const instance =  axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  config.headers.Authorization = `Bearer ${token}`
  return config
})

instance.interceptors.response.use(
  function (response) {
    return response;
  }, 
  function (error) {
    if(error.response && error.response.status){
      switch (error.response.status) {
        case 400:
          router.push('/logout');
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
import Vue from "vue";

import Editor from "@tinymce/tinymce-vue";
import Gravatar                     from 'vue-gravatar';
import CompanyLogo                  from './CompanyLogo.vue';
import CreditsAlert                 from './CreditsAlert.vue';
import Header                       from './Header.vue';
import Fieldset                     from './Fieldset.vue';
import FieldsetBasic                from './FieldsetBasic.vue';
import Footer                       from './Footer.vue';
import HeadlineCarousel             from './HeadlineCarousel.vue';
import ISPLogos                     from './ISPLogos.vue';
import Loader                       from './Loader.vue';
import Logo                         from './Logo.vue';
import NoResults                    from './NoResults.vue';
import PageTitle                    from './PageTitle.vue';
import PageSubtitle                 from './PageSubTitle.vue';
import ReportSelector               from './ReportSelector.vue';
import SystemMessage                from './SystemMessage.vue';

Vue.component('v-gravatar',         Gravatar);
Vue.component('v-company-logo',     CompanyLogo);
Vue.component('v-credits-alert',    CreditsAlert);
Vue.component('v-header',           Header);
Vue.component('v-fieldset',         Fieldset);
Vue.component('v-fieldset-basic',   FieldsetBasic);
Vue.component('v-foot',             Footer);
Vue.component('v-loader',           Loader);
Vue.component('v-isp-logos',        ISPLogos);
Vue.component('v-headline-carousel', HeadlineCarousel);
Vue.component('v-logo',             Logo);
Vue.component('v-no-results',       NoResults);
Vue.component('v-page-title',       PageTitle);
Vue.component('v-page-subtitle',    PageSubtitle);
Vue.component('v-report-selector',  ReportSelector);
Vue.component('v-system-message',   SystemMessage);
Vue.component("v-editor", Editor);

import "./readonly";
import "./windows";
import "./tests";
import "./modals";

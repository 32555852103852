<template>
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 108.3907"><path id="b" d="M0,0L54.36279,54.25116,0,108.3907H120V0H0ZM108.3907,96.78139H27.34884l42.86512-42.53023L27.34884,11.6093H108.3907s0,85.1721,0,85.17209Z" style="fill:#394eff;"/></svg>
</template>

<script>
  export default {
    name: 'VirusTotal.vue',
    props: {
      icon: {
        type: String,
        default: '$cancel'
      }
    }
  }
</script>